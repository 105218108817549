import { ActivityApi } from '@/services'
import Vue from 'vue'

const DEFAULT_ACTIVITY = { items: [], totalItems: 0 }
const state = {
  activity: { items: [], totalItems: 0 },
  myActivity: { items: [], totalItems: 0 },
  isLoading: false,
  isLoaded: false,
  currentActivity: []
}

const mutations = {
  addActivity(state, activity) {
    state.activity = [...state.activity, activity]
  },
  setActivity(state, activity) {
    state.activity = activity
  },
  setMyActivity(state, activity) {
    state.myActivity = activity
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setLoaded(state, isLoaded) {
    state.isLoaded = isLoaded
  }
}

const actions = {
  async load({ commit, state }) {
    commit('setLoading', true)
    try {
      let activity = ActivityApi.all('with_practice_activity=true').then(activity => commit('setActivity', activity))
      let myActivity = ActivityApi.get('my?with_user_activity=true').then(a => commit('setMyActivity', a))
      await Promise.all([activity, myActivity])
    } catch (err) {
      console.error(err)
      commit('setActivity', { ...DEFAULT_ACTIVITY })
    }
    commit('setLoading', false)
    commit('setLoaded', true)
  },
  // async get({commit, state}, {object_id}){
  //   ActivityApi.get('/feed/object_id/json/'
  // },
  reset({ commit }) {
    commit('setLoaded', false)
    commit('setActivity', { ...DEFAULT_ACTIVITY })
  },
  onAuthChange: ({ dispatch }, { isPracticeChanged, isNewUser, type }) => {
    if (isPracticeChanged) {
      dispatch('reset')
      if (type>0) dispatch('load')
    }
  }
}

const getters = {
  byPracticeId: state => id => state.activity.filter(s => s.practice_id == id)
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
