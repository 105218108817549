import PubNub from 'pubnub'

const state = {
  pubnubStateInstance: new PubNub({
    publishKey: process.env.VUE_APP_STATE_PUB,
    subscribeKey: process.env.VUE_APP_STATE_SUB,
    cipherKey: process.env.VUE_APP_STATE_CIPHER_KEY,
    ssl: true,
    heartbeatInterval: 540,
    userId: 'state-' + Math.random() //todo: auth.user.id or persist state - will likely need across tabs sync
    // cryptoModule: PubNub.CryptoModule.aesCbcCryptoModule(process.env.VUE_APP_STATE_CLIENT_AES_CIPHER_KEY)
  }),
  listener: null,
  status: 'NOT_INITIALIZED',
  lastTimeToken: null,
  currentTimeToken: null,
  channel: ''
}

const mutations = {
  setStatus(state, { category, lastTimetoken, currentTimetoken }) {
    state.status = category
    state.lastTimeToken = lastTimetoken
    state.currentTimeToken = currentTimetoken
  },
  setListener(state, listener) {
    state.listener = listener
  },
  setChannel(state, channel) {
    state.channel = channel
  }
}

const actions = {
  load: ({ dispatch, commit, state, rootState }) => {
    if (!(rootState.auth.user && rootState.auth.user.id)) return
    const listener = {
      status: e => commit('setStatus', e),
      message: data => ((data && data.message) || []).forEach(m => dispatch(m.type, m.payload, { root: true }))
    }
    commit('setListener', listener)
    state.pubnubStateInstance.addListener(listener)
    dispatch('subscribe')
  },
  subscribe: ({ state, rootState, commit }) => {
    const channel =
      'state_update_' +
      (rootState.auth.user.practice?.id || rootState.auth.user.id) +
      process.env.VUE_APP_CHANNEL_PREFIX
    commit('setChannel', [channel])
    state.pubnubStateInstance.subscribe({ channels: [channel] })
  },
  onAuthChange: async ({ dispatch, state }, { isPracticeChanged, isNewUser }) => {
    if (isPracticeChanged) {
      //state.pubnubStateInstance.removeListener(state.listener);
      state.pubnubStateInstance.unsubscribe({ channels: [state.channel] })
      if (!state.listener) dispatch('load')
      else if (isNewUser) dispatch('subscribe')
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
