import { ReferralApi, ReferralDemoApi, AttachmentApi } from '@/services'
import Vue from 'vue'
import moment from 'moment'
import helpers from '@/helpers'

import * as Sentry from '@sentry/vue'
const cmp = (a, b) => (a > b) - (a < b)
const sortFields = {
  date: (a, b) => cmp(new Date(a.sent_time || a.updated_at), new Date(b.sent_time || b.updated_at)), //todo: improve performance and map a date prop on the getter
  patient: (a, b) =>
    `${a.patient_firstname} ${a.patient_lastname}` > `${b.patient_firstname} ${b.patient_lastname}` ? 1 : -1, //todo: consider adding middle name
  practice: (a, b) => (`${a.practice.name}` > `${b.practice.name}` ? 1 : -1), //todo: consider adding once we determine the view format
  status: (a, b) => (helpers.getStatus(a) > helpers.getStatus(b) ? 1 : -1), //todo: this could be normalised to the model so it doesnt have to be computed so often
  in_out: (a, b, state) => (a.is_incoming > b.is_incoming ? 1 : -1),
}

const sorter = (state) => (a, b) =>
  state.referrals.sortAscending
    ? sortFields[state.referrals.sortField](a, b, state)
    : sortFields[state.referrals.sortField](b, a, state)

const searchFields = [
  'patient_firstname',
  'patient_middlename',
  'patient_lastname',
  'patient_email',
  'patient_phone',
  'doctor',
  'practice.name',
  'to_practice.name',
  'referring_doctor.email',
  'referring_doctor.first_name',
  'referring_doctor.last_name',
  'guardian_name',
]
const contextPracticeFilter = (state) => (r) =>
  state.contextPracticeId === null ||
  r.to_practice_id == state.contextPracticeId ||
  r.practice.id == state.contextPracticeId

export const is_needs_action = (r, now) => {
  now = now || moment()
  if (helpers.isArchived(r)) return false
  // if (r.is_incoming && !(r.is_important || true))
  //   return !r.receiver_viewed_at_time
  if (!r.sent_time) return !r.cancelled_time
  const isScheduled = r.scheduled_time || r.is_marked_scheduled
  const isCompleted = r.completed_time
  const isCancelled = r.cancelled_time
  const isIncoming = r.is_incoming
  if (isIncoming) {
    return (
      !(isCompleted || isCancelled) && (!isScheduled || !r.scheduled_time || now.diff(r.scheduled_time, 'minutes') > 10)
    )
  }
  //|| r.to_practice.status !== 1
  else
    return (
      // false &&
      !isCancelled &&
      (!r.sent_time || (r.requires_followup && !(now.diff(r.completed_time, 'days') > 3 || r.followup_completed_time)))
    ) // || (isCompleted && now.diff(r.completed_time, 'days') < 1) || (isCancelled && now.diff(r.cancelled_time, 'days') < 3);
}

export const filters = {
  needs_action: (payload) => (r) => is_needs_action(r, payload.now),
  // needs_action: store => r => !helpers.isArchived(r, store) && !r.scheduled_time && !(r.completed_time || r.cancelled_time),
  // needs_action_OUTGOING: store => r => !helpers.isArchived(r, store) && (r.completed_time || r.cancelled_time || !r.sent_time),
  archived: (store) => (r) => helpers.isArchived(r, store),
  all: (store) => (r) => r,
  unsent: (store) => (r) => !r.cancelled_time && !r.sent_time,
  sent: (store) => (r) => r.sent_time && !r.is_incoming,
  received: (store) => (r) =>
    r.is_incoming && (r.to_practice.specialty == 0 || !r.scheduled_time) && !(r.completed_time || r.cancelled_time),
  to_follow_up: (store) => (r) => !r.cancelled_time && r.requires_followup && !r.followup_completed_time,
  scheduled: (store) => (r) =>
    r.is_incoming && (r.scheduled_time || r.is_marked_scheduled) && !(r.completed_time || r.cancelled_time),
  completed: (store) => (r) => r.is_incoming && (r.completed_time || r.cancelled_time),
}

const state = {
  referrals: [],
  totalCount: 0,
  results: null,
  isLoading: false,
  isLoaded: false,
  searchTerm: null,
  activeFilter: null,
  contextPracticeId: null,
  error: null,
  sortField: 'date',
  sortAscending: false,
  isSuccessModalShown: false,
  isLoadingAll: false,
  isLoadedAll: false,
  now: Date.now(),
}

function resolve(path, obj = self, separator = '.') {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

function sameMembers(arr1, arr2) {
  if (!arr1 || !arr2) return arr1 == arr2
  const set1 = new Set(arr1)
  const set2 = new Set(arr2)
  return arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item))
}

const mutations = {
  setReferrals(state, referrals) {
    // state.referrals = referrals
    Vue.set(state, 'referrals', referrals)
  },
  setTotalCount(state, count) {
    state.totalCount = count
  },
  saveReferral(state, referral, force) {
    if (!referral) throw `Cannot update state with empty referral`
    if (!referral.id) throw `Cannot update state with invalid referral`

    const index = state.referrals.findIndex((r) => r.id === referral.id)

    if (index < 0) {
      state.referrals.push(referral)
    } else {
      const existing = state.referrals[index]
      //todo: should keep a list of client versions in state i.e pending changes.. if a client_version is not recognised its not from this user.
      // really a better patch mechanism would be better
      if (
        !force &&
        existing.client_version != null &&
        existing.client_version >= referral.client_version &&
        //existing.updated_at === referral.updated_at &&
        sameMembers(
          existing.attachments?.map((s) => s.id),
          referral.attachments?.map((s) => s.id)
        ) &&
        sameMembers(
          existing.history?.map((s) => s.id),
          referral.history?.map((s) => s.id)
        )
      ) {
        // console.warn('Local referral is newer')
        if (moment(referral.updated_at).isAfter(existing.updated_at)) {
          try {
            Vue.set(state.referrals, index, {
              ...existing,
              updated_at: referral.updated_at,
              is_saving: false,
            })
          } catch (e) {
            console.error(e)
          }
        }
      } else {
        if (force && existing.client_version != null && existing.client_version >= referral.client_version)
          console.info('Forcing referral update', { referral, existing: existing })
        Vue.set(state.referrals[index], 'attachments', referral.attachments)
        Vue.set(state.referrals, index, referral)
      }
    }
  },
  patchReferral(state, referral) {
    if (!referral || !referral.id) throw `Cannot patch state with invalid referral`

    const index = state.referrals.findIndex((r) => r.id === referral.id)

    if (index < 0) {
      throw `Cannot patch state with non-existing referral`
    } else {
      Object.keys(referral)
        .filter((k) => k != 'id')
        .forEach((k) => Vue.set(state.referrals[index], k, referral[k]))
    }
  },
  saveAttachment(state, { referralId, attachment }) {
    if (!referralId || !attachment.id) throw `Cannot update state with invalid attachment`

    const index = state.referrals.findIndex((r) => r.id === referralId)

    if (index < 0) {
      throw `Referral for attachment not found in state`
      return
    } else {
      const existing = state.referrals[index]
      const aIndex = existing.attachments.findIndex((a) => a.id == attachment.id)
      if (aIndex < 0) {
        state.referrals[index].attachments.unshift(attachment)
      } else {
        Vue.set(state.referrals[index].attachments, aIndex, attachment)
      }
    }
  },
  deleteAttachment(state, { referralId, attachmentId }) {
    if (!referralId || !attachmentId) throw `Cannot delete attachment with invalid payload`

    const index = state.referrals.findIndex((r) => r.id === referralId)
    if (index < 0) {
      throw `Referral for deleting attachment not found in state`
    } else {
      const existing = state.referrals[index]
      const aIndex = existing.attachments.findIndex((a) => a.id == attachmentId)
      if (aIndex < 0) {
        throw `Cannot delete: Attachment not in state`
      } else {
        state.referrals[index].attachments.splice(aIndex, 1)
      }
    }
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setLoaded(state, isLoaded) {
    state.isLoaded = isLoaded
  },
  setLoadingAll(state, isLoading) {
    state.isLoadingAll = isLoading
  },
  setLoadedAll(state, isLoaded) {
    state.isLoadedAll = isLoaded
  },
  setError(state, error) {
    state.error = error
  },
  setSort(state, { field, isAscending }) {
    if (!sortFields[field]) throw `A sorter for field ${field} has not been implemented.`
    state.sortField = field
    state.sortAscending = isAscending
  },
  //todo: should be a getter
  filterReferrals(state, { filter, term }) {
    state.activeFilter = filter || state.activeFilter //todo: access rootState and update filter based on specialty

    if (!term) {
      state.searchTerm = null
      state.results = state.referrals
    } else {
      state.searchTerm = term
      term = term.trim().toLowerCase()
      state.results = state.referrals.filter((r) =>
        searchFields.some((field) => (resolve(field, r) || '').toLowerCase().includes(term))
      )
    }
  },
  setPracticeContext(state, practiceId) {
    state.contextPracticeId = practiceId
  },
  deleteReferral(state, id) {
    const index = state.referrals.findIndex((r) => r.id === id)
    if (index === -1) return
    Vue.delete(state.referrals, index)
  },
  setIsSuccessModalShown(state, isVisible) {
    state.isSuccessModalShown = isVisible
  },
}

let cache = {}
function memoize(method, key) {
  return function () {
    let args = key || JSON.stringify(arguments)
    cache[args] = cache[args] || method.apply(this, arguments)
    return cache[args]
  }
}

const fetchReferrals = (state, commit, dispatch, getters) => async () => {
  commit('setLoadedAll', false)
  commit('setLoading', true)
  // console.log('FETCHING REFERRALS')
  try {
    let referrals = await getters.service.all()
    commit('setReferrals', referrals.results)
    commit('setTotalCount', referrals.count)
    commit('setLoaded', true)
    commit('filterReferrals', { filter: 'Needs Action' })
    //todo: should defer this and only load lazily when scrolled, or search, or accessing a referral by id
    if (referrals.results?.length === referrals.count) commit('setLoadedAll', true)

    if (!(state.isLoadedAll || state.isLoadingAll)) dispatch('loadAll')

    commit('setLoading', false)
    return referrals
  } catch (err) {
    commit('setError', err)
    commit('setReferrals', [])
    commit('setTotalCount', 0)
    console.error(err)
    Sentry.captureException(err)
  }
  commit('setLoading', false)
}

const actions = {
  async load({ commit, state, dispatch, rootState, getters }, refresh) {
    if (refresh) cache = {}
    const mKey = `referralGet${rootState.tour.activeTour || ''}${
      rootState.auth.user?.practice?.id || new Date().getTime()
    }`
    // console.log('loading referrals', mKey)
    const fetchFunc = fetchReferrals(state, commit, dispatch, getters)
    let referrals = memoize(async () => await fetchFunc(), mKey)()
    // console.log(!refresh && (state.isLoaded || state.isLoading), referrals)
    return referrals
    if (!refresh && (state.isLoaded || state.isLoading) && referrals) return referrals // this does work but techincally the promise below should be stored and returned - we want to return once setReferrals is complete
  },
  async loadAll({ commit, state, rootState, getters }) {
    if (!rootState?.auth?.user?.practice?.has_active_subscription) {
      commit('setLoadedAll', true)
      return
    }

    const mKey = `referralGet${rootState.tour.activeTour || ''}${
      rootState.auth.user?.practice?.id || new Date().getTime()
    }`

    //Get all referrals for premium users
    commit('setLoadingAll', true)
    try {
      const all = await memoize(async () => await getters.service.get('all'), mKey + 'all')()
      commit('setReferrals', [...state.referrals, ...all])
      commit('filterReferrals', { filter: null })
      commit('setLoadedAll', true)
      commit('setLoadingAll', false)
    } catch (err) {
      commit('setError', err)
      console.error(err)
      Sentry.captureException(err)
    }
  },
  reset({ commit, getters }) {
    cache = {}
    commit('setError', null)
    commit('setReferrals', [])
    commit('filterReferrals', { filter: null })
    commit('setLoaded', false)
    ReferralDemoApi && ReferralDemoApi.reset()
  },
  save: ({ commit, getters }, referral) => {
    if (referral.id) {
      referral.client_version = Date.now()
      referral.is_saving = true
      commit('saveReferral', referral)
    }
    const data = { ...referral, history: null, attachments: null }
    return new Promise((resolve, reject) =>
      getters.service
        .save(data)
        .then((s) => {
          commit('saveReferral', { ...s, is_saving: false })
          resolve(s)
        })
        .catch(reject)
    )
  },
  attach: ({ commit, state }, { referralId, attachment }) => {
    if (!referralId || !attachment) throw `Cannot update state with invalid attachment`

    const index = state.referrals.findIndex((r) => r.id === referralId)

    if (index < 0) throw `Referral ${referralId} for attachment not found in state`

    const referral = state.referrals[index]
    referral.client_version = Date.now()
    commit('saveReferral', referral)
    commit('saveAttachment', { referralId: referral.id, attachment })
  },
  deleteAttachment: ({ commit, state }, { referralId, attachmentId }) => {
    if (!referralId || !attachmentId) throw `Cannot delete attachment with invalid payload`

    const index = state.referrals.findIndex((r) => r.id === referralId)

    if (index < 0) throw `Referral ${referralId} for attachment not found in state`

    const referral = state.referrals[index]
    referral.client_version = Date.now()
    commit('saveReferral', referral)
    commit('deleteAttachment', { referralId, attachmentId })
    return new Promise((resolve, reject) => AttachmentApi.delete(attachmentId).then(resolve).catch(reject))
  },
  search: ({ commit, state }, term) => commit('filterReferrals', { filter: state.activeFilter, term }),
  filter: ({ commit, state }, filter) => commit('filterReferrals', { filter, term: state.searchTerm }),
  sort: ({ commit, state }, field) =>
    commit('setSort', {
      field,
      isAscending: state.sortField === field ? !state.sortAscending : true,
    }),
  onAuthChange: ({ dispatch }, { user, oldUser, isPracticeChanged, type }) => {
    if (isPracticeChanged) {
      dispatch('reset')
      if (type >= 0) dispatch('load')
    }
  },
  setPracticeContext: ({ commit, state, rootState }, practiceId) => {
    commit('setPracticeContext', practiceId || null)
    // const filter = (!practiceId || practiceId == rootState.auth.user.practice.id) ? 'Needs Action' : 'All';
    // commit('filterReferrals', { filter, term: state.searchTerm })
  },
  get: async ({ commit, state }, id) => {
    let referral = await getters.service.get(id)
    commit('saveReferral', referral)
  },
  stateUpdate: async ({ commit, state, getters }, { id, ts, cv, force }) => {
    const index = state.referrals.findIndex((r) => r.id == id)
    if (index >= 0) {
      const existing = state.referrals[index]
      if (
        !force &&
        existing.client_version != null &&
        // existing.updated_at >= ts &&
        existing.client_version >= cv &&
        !existing.sent_time
      ) {
        // console.info('Ignore state notification', existing.client_version, cv)
        return
      }
    }
    let referral = await getters.service.get(id)

    commit('saveReferral', referral, force)
  },
  delete: async ({ commit }, id) => {
    commit('deleteReferral', id)
    //todo:error handle
    await ReferralApi.delete(id)
  },
  readNotes: async ({ getters, commit }, id) => {
    if (getters.unreadNotes(id).length) {
      commit('patchReferral', { id, last_read_note: moment().utc() })
      await ReferralApi.patch(`${id}/read-notes`, {}, { json: false })
    }
  },
  setIsSuccessModalShown: ({ commit }, isVisible) => {
    commit('setIsSuccessModalShown', isVisible)
  },
}

const getCurrentFilter = (state, isOutgoing) => {
  const key = (
    state.referrals.activeFilter ||
    'Needs Action' ||
    (state.auth.user?.practice?.specialty == 0 ? 'Sent' : 'Received')
  )
    .toLowerCase()
    .replace(/ /g, '_')
  const now = moment()
  return filters[key]({ state, now })
  return ((isOutgoing && filters[key + '_OUTGOING']) || filters[key])({ state })
}
const getFilter = (state, filter) => {
  const key = (filter || state.referrals.activeFilter).toLowerCase().replace(/ /g, '_')
  const now = moment()
  return filters[key]({ state, now })
}
const getters = {
  all: (state, getters, root, rootGetters) =>
    rootGetters['auth/isLoggedIn'] &&
    [...(state.results || state.referrals)]
      .filter(contextPracticeFilter(state))
      .filter(getCurrentFilter(root, true))
      .sort(sorter(root)),

  get: (state) => (id) => id && state.referrals.find((r) => r.id == id),

  allCount: (state) => state.referrals.length,

  service: (state, getters, root) => (root.tour.activeTour ? ReferralDemoApi : ReferralApi),

  //todo: need to put filters here, which uses rootState to dtermine filters for current user. reduce check each filter to see if it satisifies an object.
  filterCount: (state, getters, root, rootGetters) => (filter) =>
    (state.results || state.referrals || []).filter(contextPracticeFilter(state)).filter(getFilter(root, filter))
      .length,

  contactedWithNoUpdate: (state, getters, root) =>
    state.referrals.filter(
      (r) => r.is_incoming && r.patient_called_on && !r.snooze_until_to_time && !r.is_marked_scheduled
    ),

  unreadNotes: (state, getters, root, rootGetters) => (id) => {
    const r = getters.get(id)
    const notes = r.history.filter(
      (s) =>
        s.created_by.practice_id != root.auth.user.practice.id &&
        (!r.last_read_note || moment(s.created_at) > moment(r.last_read_note))
    )
    return notes
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
