import store from '@/store'
import client from '@/http-client'

const taxonomyMap = {
  '122300000X': 0, //Dentist
  '1223G0001X': 0, //'General Dentistry',
  '261QD0000X': 0,
  '1223D0001X': 0,
  '292200000X': 0, //Dental Laboratory
  '1223D0004X': 3, //Dentist Anesthesiologist Speciality
  '1223E0200X': 1, //'Endodontist',
  '1223P0106X': 2, //'Oral Pathology',
  '1223S0112X': 3, //'Oral Surgery',
  '1223X0400X': 4, //'Orthodontist',
  '1223P0221X': 5, //'Pedodontics',
  '1223P0300X': 6, //'Periodontist',
  '1223P0700X': 7, //'Prosthodontist'
  '124Q00000X': 0, //Dental Hygienist
  '126800000X': 0 //Dental Assistant
}

export default {
  async searchAll(termOrPhone, coords) {
    if (!termOrPhone) {
      return []
    }

    var hasCharacter = /[a-zA-Z]/g.test(termOrPhone)
    var digits = termOrPhone.replace(/[^\d]/g, '')

    if (!hasCharacter && digits.length === 10) {
      return this.byPhone(digits)
    }

    return this.search(termOrPhone, undefined, undefined, 100, coords || undefined)
  },
  search(term, filterZip, isActiveExcluded, miles, { lat, lon } = {}) {
    if (!term || term.length < 3) return []
    const params = Object.entries({ zip: filterZip, isActiveExcluded, miles, lat, lon })
      .map(([k, v]) => v && `${k}=${encodeURIComponent(v)}`)
      .join('&')
    return client
      .get(`${process.env.VUE_APP_API_BASE_URL}/api/search/provider-search/${encodeURIComponent(term)}/?${params}`, {
        ignoreTokenError: true
      })
      .then(response => response.json())
  },
  async suggested(query) {
    const params = Object.entries(query)
      .map(([k, v]) => v && `${k}=${encodeURIComponent(v)}`)
      .join('&')
    return client
      .get(`${process.env.VUE_APP_API_BASE_URL}/api/search/suggested/?${params}`)
      .then(response => response.json())
  },
  async byPhone(phone) {
    return client
      .get(`${process.env.VUE_APP_API_BASE_URL}/api/search/lookup/${phone.replace(/\D/g, '')}/`)
      .then(response => response.json())
  }
}
