// import LOGO_1 from './1.png'
// import LOGO_2 from './2.png'
// import LOGO_3 from './3.png'
// import LOGO_4 from './4.png'
// import WITH_TEXT_1 from './1t.png'
// import WITH_TEXT_2 from './2t.png'
// import WITH_TEXT_3 from './3t.png'
// import WITH_TEXT_4 from './4t.png'

// export const WITH_TEXT = [WITH_TEXT_1, WITH_TEXT_2, WITH_TEXT_3, WITH_TEXT_4];
// export const LOGO = [LOGO_1,LOGO_2,LOGO_3,LOGO_4];

// import LOGO_1 from '@/assets/svg/logo/symbol-white.svg'
import WITH_TEXT_1 from '@/assets/svg/logo/logo-oneslip.svg'

export const WITH_TEXT = [WITH_TEXT_1]

// import LOGO_1 from './ph/1.png'
// import LOGO_2 from './ph/2.jpg'
// import LOGO_3 from './ph/3.jpg'
// import LOGO_5 from './ph/5.jpg'
// import LOGO_10 from './ph/10.png'
// import LOGO_11 from './ph/11.png'
// import LOGO_14 from './ph/14.png'
// import LOGO_12 from './ph/12.png'

import PH_LOGO from './ph/placeholder_clinic_circle_256.svg'

export const LOGO = [PH_LOGO]
