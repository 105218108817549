import Vue from 'vue'
import 'floating-vue/dist/style.css'
import App from './App.vue'
import router from './router'
import store from './store'
// import BaseLayout from './components/_layout/Base.vue'
// import BaseAnonymousLayout from './components/_layout/BaseAnonymous.vue'
import CardHeader from './components/shared/CardHeader'
// import 'bootstrap'
import moment from 'moment'
import VeeValidate from 'vee-validate'
import Paginate from 'vuejs-paginate'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import helpers from './helpers-vue'
// import VueTour from './plugins/vue-tour'
import Toasted from 'vue-toasted'
// import VueGtag from 'vue-gtag'
import './plugins/fontawesome'
import WithPremium from '@/plugins/premium'
// import PostHog from './plugins/posthog'
import Device from './plugins/device'

import ContentPlaceholders from '@/components/loaders/ContentPlaceholders.vue'
import ContentPlaceholdersHeading from '@/components/loaders/ContentPlaceholdersHeading.vue'
import ContentPlaceholdersImg from '@/components/loaders/ContentPlaceholdersImg.vue'
import ContentPlaceholdersText from '@/components/loaders/ContentPlaceholdersText.vue'
// import ybug from './plugins/ybug'
import helpscout from './plugins/helpscout-beacon'
import Interval from './plugins/interval'
import FloatingVue from 'floating-vue'
import ScrollSnap from './plugins/scroll-snap'

// require('vue-tour/dist/vue-tour.css')
const isProduction = process.env.NODE_ENV === 'production'
const isDevelopment = process.env.NODE_ENV === 'development'
if (process.env.VUE_APP_DISABLE_SENTRY != 'true') {
  Sentry.init({
    dsn: 'https://a24f79b71ab240c3ae0e8833121228ae@sentry.io/1472604',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [/api([^.]+)?.refera.com/],
      }),
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category == 'xhr') {
        if (
          [
            'track.refera.com',
            't.refera.com',
            'pndsn.com',
            'doubleclick.net',
            'stripe.com',
            'google-analytics.com',
          ].some((s) => breadcrumb?.data?.url?.indexOf(s))
        )
          return null
      }
      return breadcrumb
    },
    tracesSampleRate: isProduction ? 0.2 : 0.5,
    debug: false,
    environment: process.env.VUE_APP_ENV_NAME,
    release: 'refera-web@' + process.env.VUE_APP_VERSION + '-' + process.env.VUE_APP_BUILD_VERSION,
  })
  Sentry.setTag('version', process.env.VUE_APP_VERSION)
} else console.info('Sentry is disabled')

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: (input) => (input === 'just now' ? input : input + ' ago'),
    s: 'just now',
    ss: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})
Vue.prototype.moment = moment
Vue.prototype.confirm = (...args) => window.confirm.apply(window, args) //todo: this is for X'ing the referral - could use VueRouter navigation guards and imlement a prettier custom dialog w/ isDirty flags;

Vue.config.productionTip = false
Vue.prototype.$sentry = Sentry
Vue.prototype.$exception = isDevelopment ? console.error : Sentry.captureException

Vue.component('base-layout', () => import('./components/_layout/Base.vue'))
Vue.component('baseanonymous-layout', () => import('./components/_layout/BaseAnonymous.vue'))
Vue.component('baseprint-layout', () => import('./components/_layout/BasePrint.vue'))
Vue.component('baseinbox-layout', () => import('./components/_layout/BaseInbox.vue'))

Vue.use(FloatingVue, {
  distance: 5,

  themes: {
    tooltip: {
      delay: { show: 100, hide: 300 },
    },
    'light-tooltip': {
      $extend: 'tooltip',
      // Other options (see the 'Global options' section)
    },
    'light-sm-tooltip': {
      $extend: 'tooltip',
    },
    'light-lg-tooltip': {
      $extend: 'light-tooltip',
    },
  },
})
Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    invalid: 'is-invalid',
  },
})
Vue.use(VuejsDialog, { html: true })
// Vue.use(VueTour)
Vue.use(Toasted, {
  keepOnHover: true,
  duration: 15e3,
  theme: 'toasted-primary',
  className: 'refera-toast',
  iconPack: 'callback', //'fontawesome',
  position: 'top-right',
})
// Vue.use(
//   VueGtag,
//   {
//     config: {
//       id: 'UA-156430742-1',
//       linker: {
//         accept_incoming: true
//       }
//     }
//     // config: {
//     //   id: 'AW-607347500',
//     //   linker: {
//     //     accept_incoming: true
//     //   }
//     // }
//   },
//   router
// )
Vue.use(helpers, router, store)

Vue.component('Placeholders', ContentPlaceholders)
Vue.component('PlaceholdersHeading', ContentPlaceholdersHeading)
Vue.component('PlaceholdersImg', ContentPlaceholdersImg)
Vue.component('PlaceholdersText', ContentPlaceholdersText)

Vue.component('paginate', Paginate)
Vue.component('card-header', CardHeader)

Vue.component('from-now', {
  name: 'FromNow',
  props: {
    tag: { type: String, default: 'span' },
    time: { default: () => moment().toISOString() },
    dropFixes: { default: false, type: Boolean },
  },
  data() {
    return { fromNow: moment(this.time).fromNow(this.dropFixes) }
  },
  mounted() {
    Interval.register(this.updateFromNow)
    this.$watch('time', this.updateFromNow)
  },
  beforeDestroy() {
    Interval.unregister(this.intervalId)
  },
  methods: {
    updateFromNow() {
      var newFromNow = moment(this.time).fromNow(this.dropFixes)
      if (newFromNow !== this.fromNow) {
        this.fromNow = newFromNow
      }
    },
  },

  render(h) {
    return h(this.tag, { key: this.fromNow }, this.fromNow)
  },
})

Vue.filter('formatSize', function (size) {
  size = parseInt(size || 0)
  var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
})

Vue.prototype.$Notification = window.Notification
Vue.prototype.$isMobile = Device.mobile()
Vue.prototype.$track = function () {}
Vue.use(WithPremium, router, store)
// Vue.use(ScrollSnap)
// Vue.use({
//   install(Vue, options) {
//     Vue.prototype.globalFunc = () => {}
//   }
// })

// Vue.use(PostHog, router, store)
// Vue.use(ybug, router, store)
Vue.use(helpscout, router, store)

Vue.filter('phone', (p) => {
  if (/\(\d{3}\)\s\d{3}-\d{4}$/.test(p || '')) return p
  p = p?.replace(/\D/g, '')
  return (p?.length >= 10 && p.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})(.+)?$/, '($1) $2-$3')) || p
})
if (process.env.NODE_ENV === 'development') {
  Vue.config.errorHandler = function (err, vm, info) {
    console.warn(info)
    console.error(err)
    throw err
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
  }
  window.onerror = function (msg, url, line, col, error) {
    console.warn(msg, url, line, col)
    console.error(error)
    //code to handle or report error goes here
  }
}

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
