import { SettingsApi } from '../../services'
import { WITH_TEXT, LOGO } from './LOGOS'

const DEFAULT_CONFIG = {
  v: process.env.VUE_APP_VERSION,
  anon: parseInt(process.env.VUE_APP_ANON_PID || '1'), //preload before server provides latest
  rl: parseInt(process.env.VUE_APP_REFERRAL_LIMIT || '20'),
}
const state = {
  isSidebarVisible: false,
  isSidebarEnabled: false,
  isGettingStartedVideoVisible: false,
  isPaymentModalVisible: false,
  isBannerVisible: true,
  config: DEFAULT_CONFIG,
  isLoading: false,
  logo: 1,
}

/*
export interface RemoteConfig {
  v: string
  dn: string
  api: string
  pub: string
  app: string
  dm: number
  anon: number
  pt: Pt
  ts: string,
  rl: number // referral limit
}
*/

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setConfig(state, config) {
    state.config = config
  },
  setSidebar(state, isVisible) {
    state.isSidebarVisible = isVisible
  },
  setSidebarEnabled(state, isEnabled) {
    state.isSidebarEnabled = isEnabled
  },
  setIsGettingStartedVideoVisible(state, isVisible) {
    state.isGettingStartedVideoVisible = isVisible
  },
  setIsPaymentModalVisible(state, isVisible) {
    state.isPaymentModalVisible = isVisible ?? !state.isPaymentModalVisible
  },
  setIsBannerVisible(state, isVisible) {
    state.isBannerVisible = isVisible
  },
  setLogo(state, logo) {
    state.logo = logo
  },
}

const actions = {
  async load({ commit, state }) {
    if (state.config?.ts) return state.config

    commit('setLoading', true)
    let config = null
    try {
      config = await SettingsApi.all({}, { anonymous: true })
      if (config?.anon !== state.config?.anon) {
        console.warn('Config mismatch on server', { l: config?.anon, s: state.config?.anon })
      }
      commit('setConfig', config)
    } catch (err) {
      console.error(err)
      commit('setConfig', DEFAULT_CONFIG)
      throw err
    }
    commit('setLoading', false)
    return config
  },
  setSidebar({ commit, state }, isVisible) {
    commit('setSidebar', isVisible)
  },
  setSidebarEnabled({ commit, state }, isEnabled) {
    commit('setSidebarEnabled', isEnabled)
  },
  toggleSidebar({ commit, state }) {
    commit('setSidebar', !state.isSidebarVisible)
  },
  setIsGettingStartedVideoVisible({ commit, state }, isVisible) {
    commit('setIsGettingStartedVideoVisible', isVisible)
  },
  setIsPaymentModalVisible({ commit, state }, isVisible) {
    commit('setIsPaymentModalVisible', isVisible ?? !state.isPaymentModalVisible)
  },
  setIsBannerVisible({ commit, state }, isVisible) {
    commit('setIsBannerVisible', isVisible)
  },
}

const getters = {
  PracticeTypes(state) {
    return Object.keys(state.config.pt)
  },
  WithReasons(state) {
    const withReasons = {}
    for (const practiceType in state.config.pt) {
      withReasons[practiceType] = state.config.pt[practiceType].reasons
    }
    return withReasons
  },
  FlatReasons: (state, getters) =>
    Object.keys(getters.WithReasons).reduce((a, c) => ({ ...a, ...getters.WithReasons[c] }), {}),

  PracticeDataArray: (state) =>
    Object.entries(state.config.pt).map(([name, { plural, abbr, reasons }]) => ({
      name: name,
      plural,
      abbr,
      reasons,
    })),

  PracticeTypesPlural(state, getters) {
    return getters.PracticeDataArray.map(({ plural }) => plural)
  },
  PracticeTypesAbbr(state) {
    return Object.values(state.config.pt).map(({ abbr }) => abbr)
  },
  DemoId: (state) => state.config?.dm,
  AnonId: (state) => state.config?.anon,
  Logo: (state) => LOGO[state.logo % LOGO.length],
  LogoText: (state) => WITH_TEXT[state.logo % WITH_TEXT.length],
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
