import { OpReportApi } from '@/services'
import Vue from 'vue'
import moment from 'moment'
import helpers from '@/helpers'
import * as Sentry from '@sentry/vue'

const sortFields = {
  date: (a, b) => new Date(a.sent_time || a.updated_at) - new Date(b.sent_time || b.updated_at), //todo: improve performance and map a date prop on the getter
  patient: (a, b, state) => {
    let ao = a.referral_id ? state.referrals.referrals.find(s => s.id == a.referral_id) : a
    let bo = b.referral_id ? state.referrals.referrals.find(s => s.id == b.referral_id) : b

    return `${ao.patient_firstname} ${ao.patient_lastname}`.localeCompare(
      `${bo.patient_firstname} ${bo.patient_lastname}`
    )
  },
  practice: (a, b) =>
    (a.is_incoming ? a?.practice : a?.to_practice).name?.localeCompare(
      (b?.is_incoming ? b?.practice : b?.to_practice)?.name
    ), //todo: consider adding once we determine the view format
  doctor: (a, b) =>
    helpers.displayUsername(a?.referring_doctor).localeCompare(helpers.displayUsername(b?.referring_doctor)), //todo: consider adding once we determine the view format
  status: (a, b) => new Date(b.sent_time) - new Date(a.sent_time), //todo: this could be normalised to the model so it doesnt have to be computed so often
  in_out: (a, b, state) => (a.is_incoming > b.is_incoming ? 1 : -1)
}

const sorter = state => (a, b) =>
  state.opreports.sortAscending
    ? sortFields[state.opreports.sortField](a, b, state)
    : sortFields[state.opreports.sortField](b, a, state)

const searchFields = [
  'patient_firstname',
  'patient_lastname',
  'referral_fullname',
  'referral_phone',
  'comment',
  'doctor',
  'practice.name',
  'to_practice.name',
  'referring_doctor.email',
  'referring_doctor.first_name',
  'referring_doctor.last_name',
  'guardian_name'
]
const contextPracticeFilter = state => r =>
  state.contextPracticeId === null ||
  r.to_practice_id == state.contextPracticeId ||
  r.practice.id == state.contextPracticeId

const filters = {
  needs_action: payload => r => true,
  all: store => r => r,
  unsent: store => r => !r.sent_time,
  sent: store => r => r.sent_time && !r.is_incoming,
  received: store => r => r.is_incoming
}

function resolve(path, obj = self, separator = '.') {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

const state = {
  opReports: [],
  totalCount: 0,
  results: null,
  isLoading: false,
  isLoaded: false,
  searchTerm: null,
  activeFilter: 'Unsent',
  contextPracticeId: null,
  sortField: 'date',
  sortAscending: false,
  isLoadingAll: false,
  isLoadedAll: false,
  now: Date.now()
}

const mutations = {
  addOpReport(state, opReport) {
    state.opReports = [...state.opReports, opReport]
  },
  setOpReports(state, opReports) {
    if (!opReports) console.warn('Op Reports Empty')
    state.opReports = opReports
  },
  setTotalCount(state, count) {
    state.totalCount = count
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setLoaded(state, isLoaded) {
    state.isLoaded = isLoaded
  },
  setLoadingAll(state, isLoading) {
    state.isLoadingAll = isLoading
  },
  setLoadedAll(state, isLoaded) {
    state.isLoadedAll = isLoaded
  },
  saveOpReport(state, opReport) {
    if (!opReport || !opReport.id) throw `Cannot update state with invalid opReport`
    const index = state.opReports.findIndex(r => r.id === opReport.id)

    if (index < 0) {
      state.opReports.push(opReport)
    } else {
      Vue.set(state.opReports, index, opReport)
    }
  },
  deleteOpReport(state, id) {
    const index = state.opReports.findIndex(r => r.id === id)
    Vue.delete(state.opReports, index)
  },
  setPracticeContext(state, practiceId) {
    state.contextPracticeId = practiceId
  },
  setSort(state, { field, isAscending }) {
    if (!sortFields[field]) throw `A sorter for field ${field} has not been implemented.`
    state.sortField = field
    state.sortAscending = isAscending
  },
  //todo: should be a getter
  filterOpReports(state, { filter, term }) {
    state.activeFilter = filter || state.activeFilter || 'Unsent'

    if (!term) {
      state.searchTerm = null
      state.results = state.opReports
    } else {
      state.searchTerm = term
      term = term.trim().toLowerCase()
      state.results = state.opReports.filter(r =>
        searchFields.some(field => (resolve(field, r) || '').toLowerCase().includes(term))
      )
    }
  }
}

const actions = {
  async load({ rootState, commit, dispatch, state }) {
    commit('setLoading', true)
    try {
      let opReports = await OpReportApi.all()
      commit('setOpReports', opReports?.results || [])
      commit('setTotalCount', opReports.count)
      commit('filterOpReports', {
        filter: rootState.auth.user?.practice?.specialty == 0 ? 'Received' : 'Unsent'
      })
      if (!(state.isLoadedAll || state.isLoadingAll)) dispatch('loadAll')
    } catch (err) {
      console.error(err)
      commit('setOpReports', [])
      commit('setTotalCount', 0)
    }
    commit('setLoading', false)
    commit('setLoaded', true)
  },
  async loadAll({ commit, state, rootState, getters }) {
    // if (!rootState?.auth?.user?.practice?.has_active_subscription) return

    //Get all referrals for premium users
    commit('setLoadingAll', true)
    try {
      const all = await OpReportApi.get('all')
      if (all?.length) commit('setOpReports', [...state.opReports, ...all])
      commit('filterOpReports', { filter: null })
      commit('setLoadedAll', true)
      commit('setLoadingAll', false)
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
    }
  },
  reset({ commit }) {
    commit('setLoaded', false)
    commit('setOpReports', [])
  },
  onAuthChange: ({ dispatch }, { isPracticeChanged, type }) => {
    if (isPracticeChanged) {
      dispatch('reset')
      if (type>0) dispatch('load')
    }
  },
  save: ({ commit, getters }, opReport) => {
    return new Promise((resolve, reject) =>
      OpReportApi.save(opReport)
        .then(s => {
          commit('saveOpReport', s)
          resolve(s)
        })
        .catch(reject)
    )
  },
  setPracticeContext: ({ commit, state, rootState }, practiceId) => {
    commit('setPracticeContext', practiceId || null)
  },
  delete: async ({ commit }, id) => {
    commit('deleteOpReport', id)
    //todo:error handle
    await OpReportApi.delete(id)
  },
  sort: ({ commit, state }, field) =>
    commit('setSort', {
      field,
      isAscending: state.sortField === field ? !state.sortAscending : true
    }),
  search: ({ commit, state }, term) => commit('filterOpReports', { filter: state.activeFilter, term }),
  filter: ({ commit, state }, filter) => commit('filterOpReports', { filter, term: state.searchTerm }),
  stateUpdate: async ({ commit }, id) => {
    let opreport = await OpReportApi.get(id)
    commit('saveOpReport', opreport)
  }
}

const getCurrentFilter = (state, isOutgoing) => {
  const key = state.opreports.activeFilter.toLowerCase().replace(/ /g, '_')
  const now = moment()
  return filters[key]({ state, now })
}
const getFilter = (state, filter) => {
  const key = (filter || state.opreports.activeFilter).toLowerCase().replace(/ /g, '_')
  const now = moment()
  return filters[key]({ state, now })
}

const getters = {
  byPracticeId: state => id => state.opReports.filter(s => s.practice_id == id),
  get: state => id => id && state.opReports.find(r => r.id == id),
  all: (state, getters, root, rootGetters) =>
    rootGetters['auth/isLoggedIn'] &&
    (state.results || state.opReports)
      .filter(contextPracticeFilter(state))
      .filter(getCurrentFilter(root, true))
      .sort(sorter(root)),
  filterCount: (state, getters, root, rootGetters) => filter =>
    rootGetters['auth/isLoggedIn'] &&
    (state.results || state.opReports || []).filter(contextPracticeFilter(state)).filter(getFilter(root, filter)).length
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
