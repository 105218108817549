import { LocationApi } from '../../services'
const LOCATION_TYPE = {
  IP: 'ip',
  BROWSER: 'browser',
  MANUAL: 'manual',
  FAILED_BROWSER: 'failed_browser',
}
const state = {
  location: null,
  ipLocation: null,
  browserLocation: null,
  lastIpLocationTime: null,
  isLoadingCurrentLocation: false,
  error: null,
}
const fromBrowserData = (data) => ({})
const fromIpData = (data) => ({
  lon: data.longitude,
  lat: data.latitude,
  city: data.city, //City may not match our internal db of us cities
  state: data.region_code,
  zip: data.zip,
  //   country: data.country_code,
  //   countryName: data.country_name,
  //   timezone: data.time_zone?.id,
  //   timezoneName: data.time_zone?.name,
  //   timezoneAbbr: data.time_zone?.abbr,
  //   timezoneOffset: data.time_zone?.current_time?.offset,
  //   timezoneIsDst: data.time_zone?.is_daylight_saving,
  //   currency: data.currency?.code,
  //   currencyName: data.currency?.name,
  //   currencySymbol: data.currency?.symbol,
  //   isp: data.connection?.isp,
  //   org: data.connection?.organization,
  //   as: data.connection?.asn,
  //   asName: data.connection?.asn_name,
  //   asDomain: data.connection?.domain,
  //   asRoute: data.connection?.route,
  //   asType: data.connection?.type,
  //   asCountry: data.connection?.country_code,
  //   asCountryName: data.connection?.country_name,
  //   asCity: data.connection?.city,
  //   asLatitude: data.connection?.latitude,
  //   asLongitude: data.connection?.longitude,
  //   asZip: data.connection?.zip,
  //   asTimezone: data.connection?.time_zone?.id,
  //   asTimezoneName: data.connection?.time_zone?.name,
  //   asTimezoneAbbr: data.connection?.time_zone?.abbr,
  //   asTimezoneOffset: data.connection?.time_zone?.current_time?.offset,
  //   asTimezoneIsDst: data.connection?.time_zone?.is_daylight_saving,
  //   asCurrency: data.connection?.currency?.code,
  //   asCurrencyName: data.connection?.currency?.name,
  //   asCurrencySymbol: data.connection?.currency?.symbol,
  //   asIsp: data.connection?.isp,
  //   asOrg: data.connection?.organization,
  //   asAs: data.connection?.asn,
  //   asAsName: data.connection?.asn_name,
  //   asAsDomain: data.connection?.domain,
  //   asAsRoute: data.connection?.route,
  //   asAsType: data.connection?.type,
  //   asAsCountry: data.connection?.country_code,
  //   asAsCountryName: data.connection?.country_name,
  //   asAsCity: data.connection?.city,
  //   asAsLatitude: data.connection?.latitude,
  //   asAsLongitude: data.connection?.longitude,
  //   asAsZip: data.connection?.zip,
  //   asAsTimezone: data.connection?.time_zone?.id,
  //   asAsTimezoneName: data.connection?.time_zone?.name,
  //   asAsTimezoneAbbr: data.connection?.time_zone?.abbr,
  //   asAsTimezoneOffset: data.connection?.time_zone?.current_time?.offset,
  //   asAsTimezoneIsDst: data.connection?.time_zone?.is_day
})

const actions = {
  async getIpLocation({ commit, state }) {
    commit('SET_ERROR', '')
    if (state.lastIpLocationTime && state.lastIpLocationTime > new Date(new Date().getTime() - 1000 * 60 * 1)) {
      console.warn('Surpressing IP location lookup', state.lastIpLocationTime)
      return
    }
    //todo: undocumented property and probably not supported in future versions
    const { error, info } = this._vm.$helpers
    try {
      // Make an API request to get the IP location
      const response = await fetch('https://api.ipstack.com/check?access_key=76a08686ef297eaec87b1b7c466be250')
      const data = await response.json()
      if (data?.country_code === 'US' && data?.zip) {
        commit('SET_IP_LOCATION', data)
        const { longitude, latitude } = data
        const resolvedCity = await LocationApi.get(`lookup?latitude=${latitude}&longitude=${longitude}`, {
          anonymous: true,
        })
        commit('SET_LOCATION', resolvedCity)
        commit('SET_LAST_IP_LOCATION_TIME', new Date())
      } else if (data?.country_code) info('Refera is currently only available in the United States')
    } catch (error) {
      //todo: set error
      commit('SET_IP_LOCATION', null)
      commit('SET_ERROR', error)
      // Handle any errors
      console.error('Error getting IP location:', error)
    }
  },
  async getBrowserLocation({ commit, state }, { overwriteLocation = false }) {
    commit('SET_ERROR', '')
    //todo: undocumented property and probably not supported in future versions
    const { error, info } = this._vm.$helpers
    if (!navigator.geolocation) {
      error('Your browser does not support location services.')
      return
    }
    //todo: setLoadingState
    commit('SET_IS_LOADING_CURRENT_LOCATION', true)

    try {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          commit('SET_BROWSER_LOCATION', position)
          if (overwriteLocation) {
            const { latitude, longitude } = position.coords
            const data = await LocationApi.get(`lookup?latitude=${latitude}&longitude=${longitude}`)
            if (data.state) {
              commit('SET_LOCATION', data)
            } else {
              commit('SET_ERROR', "It seems like you're outside the United States")
              commit('SET_LOCATION', null)
              info("It seems like you're outside the United States")
            }
          }
        },
        (err) => {
          error('Failed to get your location. ' + (err.message || 'Please enter your location manually'))
        },
        { timeout: 5000 }
      )
    } catch (err) {
      error('Failed to get your location')
    }
    commit('SET_IS_LOADING_CURRENT_LOCATION', false)
  },
  setLocation({ commit, dispatch, state }, location) {
    commit('SET_LOCATION', location)
    // dispatch('suggested/load', {}, { root: true })
  },
}

const mutations = {
  SET_LOCATION(state, location) {
    state.location = location
  },
  SET_IP_LOCATION(state, location) {
    state.ipLocation = location
  },
  SET_BROWSER_LOCATION(state, location) {
    state.browserLocation = location
  },
  SET_LAST_IP_LOCATION_TIME(state, time) {
    state.lastIpLocationTime = time
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_IS_LOADING_CURRENT_LOCATION(state, isLoading) {
    state.isLoadingCurrentLocation = isLoading
  },
}

const getters = {
  // Define any getters if needed
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
