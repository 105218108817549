import { NetworkApi, PracticeApi, DoctorApi } from '@/services'
import Vue from 'vue'

const state = {
  practices: [],
  doctors: [],
  isLoading: false,
  isLoaded: false
}

const mutations = {
  setPractices(state, practices) {
    state.practices = practices
  },
  setDoctors(state, doctors) {
    state.doctors = doctors
  },
  savePractice(state, practice) {
    if (!practice || !practice.id) {
      throw `Cannot update state with invalid practice`
    }
    const index = state.practices.findIndex(u => u.id == practice.id)
    if (index === -1) state.practices.push(practice)
    else Vue.set(state.practices, index, practice)
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setLoaded(state, isLoaded) {
    state.isLoaded = isLoaded
  }
}

const actions = {
  async load({ commit, state, rootState, dispatch, rootGetters }) {
    // if (rootGetters['auth/type'] < 1) {
    //   commit('setLoaded', true)
    //   return
    // }
    commit('setLoading', true)
    try {
      let practices = await NetworkApi.all()
      const allPractices = [rootState.auth.user.practice, ...practices]
      commit('setPractices', allPractices)
      commit(
        'setDoctors',
        allPractices.reduce((a, c) => a.concat(c.doctors.map(d => ({ ...d, practice: c.id }))), [])
      ) //todo: make Getter
    } catch (err) {
      commit('setPractices', [])
    }
    commit('setLoading', false)
    commit('setLoaded', true)
  },
  async loadDoctors({ commit, state, rootState, dispatch }, practice) {
    commit('setLoading', true)
    try {
      let doctors = await DoctorApi.get(practice)
      commit(
        'setDoctors',
        doctors.map(d => ({ ...d, practice }))
      )
    } catch (err) {
      console.error(err)
      commit('setDoctors', [])
    }
    commit('setLoading', false)
  },
  reset({ commit }) {
    commit('setLoaded', false)
    commit('setPractices', [])
    commit('setDoctors', [])
  },
  onAuthChange: ({ dispatch }, { user, oldUser, isPracticeChanged, type }) => {
    if (isPracticeChanged) {
      dispatch('reset')
      if (type >= 0) dispatch('load')
    }
  },
  save: async ({ commit, dispatch, rootState }, practice) => {
    try {
      let data = await PracticeApi.save(practice)
      commit('savePractice', data)
      const user = rootState.auth.user
      if (user && user.practice.id === practice.id)
        dispatch('auth/updateCurrentUser', { ...user, practice }, { root: true })
      return data
    } catch (err) {
      throw err
    }
  },
  refreshCurrentUserPractice: ({ rootState, dispatch, commit }) => {
    return new Promise((resolve, reject) => {
      const user = rootState.auth.user
      PracticeApi.get(user.practice.id)
        .then(practice => {
          commit('savePractice', practice)
          dispatch('auth/updateCurrentUser', { ...user, practice }, { root: true }).then(resolve)
        })
        .catch(reject)
    })
  },
  create: async ({ commit, rootState }, practice) => {
    return await PracticeApi.save(practice, { anonymous: true })
  },
  addToNetwork: async ({ commit, dispatch }, practice) => {
    let network = await NetworkApi.create(practice)
    return network && network.to_practice
  },
  stateUpdate: async ({ commit, rootState, dispatch }, { id }) => {
    //todo: have a global network_update channel the publishes ids,
    if (!id) return
    const user = rootState.auth.user
    if (!user) return
    if (user && user.practice.id == id) {
      await dispatch('refreshCurrentUserPractice')
    } else {
      let isNew = state.practices.findIndex(u => u.id == id)
      if (isNew) {
        //todo: horrible but allow the create-referral/suggested view to handle remaining events (redirect mainly)
        await await new Promise(resolve => setTimeout(resolve, 5000))
      }
      let practice = await NetworkApi.get(id)
      commit('savePractice', practice)
      // if (isNew)
      //   Vue.toasted.info(`${practice.name} have been added to your network!`)
    }
  }
}

const getters = {
  assignable: (state, getters, root) =>
    root.auth.token &&
    root.auth.user &&
    root.auth.user.practice &&
    state.practices.filter(p => p.id !== root.auth.user.practice.id),

  byId: state => practiceId => state.practices.find(p => p.id === practiceId),

  network: (state, getters, root) =>
    root.auth.token &&
    root.auth.user &&
    root.auth.user.practice &&
    state.practices
      .filter(p => p.id !== root.auth.user.practice.id)
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => (a.id == root.settings.config?.dm ? -1 : 1)),

  inactive: (state, getters, root) =>
    root.auth.token &&
    root.auth.user &&
    root.auth.user.practice &&
    state.practices.filter(p => p.id !== root.auth.user.practice.id && p.status !== 1)
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
