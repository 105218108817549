import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import practices from './modules/practices'
import referrals from './modules/referrals'
import notifications from './modules/notifications'
import state from './modules/state'
import users from './modules/users'
import settings from './modules/settings'
import appointments from './modules/appointments'
import suggested from './modules/suggested'
import tour from './modules/tour'
// import sms from './modules/sms'
import opreports from './modules/opreports'
import billing from './modules/billing'
import activity from './modules/activity'
import geo from './modules/geo'
import createMutationsSharer, {
  BroadcastChannelStrategy,
  LocalStorageStratery as LocalStorageStrategy
} from 'vuex-shared-mutations'
import VuexPersistence from 'vuex-persist'
import * as Sentry from '@sentry/vue'

Vue.use(Vuex)
let isAuthUserTracked = false
let isInitialLoad = true
const onAuthChangeOrder = ['practices']

const authenticationWatch = store => {
  store.watch(
    state => state.auth.user,
    async (user, oldUser) => {
      let isOldUser = oldUser && Object.keys(oldUser).length
      let isNewUser = user && Object.keys(user).length

      let isFreshLogin = !isOldUser && isNewUser
      let isSwitchingUser = isOldUser && isNewUser && oldUser.id != user.id
      const isPracticeChanged = !!(!isNewUser || isFreshLogin || oldUser.practice?.id !== user.practice?.id)
      const type = store.getters['auth/type']
       ;[...new Set(onAuthChangeOrder.concat(...Object.keys(store.state)))].forEach(async state => {
        const action = `${state}/onAuthChange`
        // If the store does not have an refresh action ignore
        if (store._actions[action])
          await store.dispatch(action, {
            user,
            oldUser,
            isPracticeChanged,
            isNewUser,
            isFreshLogin,
            isLoggedOut: !isNewUser,
            isSwitchingUser,
            isInitialLoad,
            type
          })
      })

      if (!isNewUser) {
        try {
          Sentry.configureScope(scope => scope.setUser(null))
        } catch (e) {}
      }
      if (isFreshLogin || (isNewUser && oldUser.id != user.id) || (isNewUser && !isAuthUserTracked)) {
        isAuthUserTracked = true
        try {
          Sentry.configureScope(scope => scope.setUser(null))
          Sentry.setUser({
            user_id: user.id,
            name: user.first_name + ' ' + user.last_name,
            email: user.email,
            practice: user.practic?.name,
            practice_id: user.practice?.id
          })
        } catch (err) {
          this.$exception(err)
        }
      }
      isInitialLoad = false
    }
  )
  store.watch(
    state => state.auth.token,
    (token, oldToken) => {
      //set cookie for refera.com
      document.cookie = token
        ? `refera_token=${token};path=/;domain=refera.com`
        : `token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    }
  )

  store.watch(
    state => state.geo.location,
    (location, oldLocation) => {
      if (location?.lat) {
        store.dispatch('suggested/load', {
          refresh: true
        })
      }
    }
  )
}

const LOCAL_STORAGE_VERSION = 2
const getSyncStrategy = key => {
  if (BroadcastChannelStrategy.available()) {
    return new BroadcastChannelStrategy({ key })
  }
  if (LocalStorageStrategy.available()) {
    return new LocalStorageStrategy({ key })
  }
  console.warn('Browser does not support any sync strategy - tabs/windows will not be syncronized.')
}

let plugins = [
  createMutationsSharer({
    predicate: ['auth/setCurrentUser', 'auth/setToken', 'auth/setLogin', 'geo/setLocation'],
    strategy: getSyncStrategy(`REFERA_SYNC_AUTH_V${LOCAL_STORAGE_VERSION}`)
  }),
  new VuexPersistence({
    key: `refera_state_v${LOCAL_STORAGE_VERSION}`,
    restoreState: (key, storage) => {
      const state = storage.getItem(key)
      if (state) {
        try {
          return JSON.parse(state)
        } catch (e) {
          console.error('Error parsing state', e)
        }
      }
    },
    reducer: state => ({
      auth: { ...state.auth, error: null, isTrackingLoaded: false },
      geo: { location: state.geo.location }
    })
  }).plugin,
  authenticationWatch
]

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    practices,
    referrals,
    notifications,
    state,
    users,
    settings,
    // report, // lazy-loaded
    // adminReport, // lazy-loaded
    appointments,
    suggested,
    tour,
    // sms, //lazy loaded
    opreports,
    billing,
    activity,
    geo
  },
  strict: false,
  plugins
})
