import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import {
  faCircle,
  faEnvelopeOpen,
  faCalendarAlt,
  faFileMedicalAlt,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faPaperclip,
  faDownload,
  faPrint,
  faVideo,
  faSearch,
  faEllipsisH,
  faEllipsisV,
  faUserPlus,
  faEdit,
  faPaperPlane,
  faClone,
  faTimes,
  faTimesCircle,
  faCheckCircle,
  faCalendar,
  faBell,
  faFileMedical,
  faStickyNote,
  faCommentAltDots,
  faPlayCircle,
  faPlus,
  faMinus,
  faCheck,
  faTrash,
  faEye,
  faEyeSlash,
  faCommentAlt,
  faComments,
  faNotesMedical,
  faMobile,
  faPhoneRotary,
  faCalendarDay,
  faPencil,
  faLevelUp,
  faClock,
  faInbox,
  faQuestionCircle,
  faLocation,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons'
// import {
// } from '@fortawesome/pro-light-svg-icons'
import {
  faUserCircle,
  faFile,
  faFilePdf,
  faFileImage,
  faLock,
  faLockOpen,
  faCopy,
  faSave,
  faSpinner,
  faMinusCircle,
  faPlusCircle,
  faCheckCircle as fasCheckCircle,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faSort,
  faSortUp,
  faSortDown,
  faPhone,
  faGlobe,
  faAddressBook,
  faMapMarkerAlt,
  faIdCardAlt,
  faEnvelope,
  faUserMd,
  faUserTie,
  faTasks,
  faCreditCard,
  faArrowDown,
  faArrowUp,
  faBellSlash,
  faCloudDownloadAlt,
  faPlay,
  faShare,
  faSms,
  faBars,
  faChartPie,
  faCalendarCheck,
  faExclamationCircle,
  faThermometerEmpty,
  faLink,
  faBolt,
  faShareAll,
  faSpinnerThird,
  faHandHoldingMedical,
  faPhoneVolume,
  faThumbtack,
  faBookmark,
  faStar,
  faCaretLeft,
  faCaretRight,
  faHistory
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faUserCircle,
  faSearch,
  faPaperPlane,
  faFile,
  faFilePdf,
  faFileImage,
  faLock,
  faLockOpen,
  faCheck,
  faTimes,
  faPrint,
  faCopy,
  faSave,
  faSpinner,
  faInfoCircle,
  faPlusCircle,
  faMinusCircle,
  faTimesCircle,
  faCheckCircle,
  fasCheckCircle,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faSort,
  faSortUp,
  faSortDown,
  faTrash,
  faPhone,
  faGlobe,
  faAddressBook,
  faMapMarkerAlt,
  faIdCardAlt,
  faCommentAlt,
  faComments,
  faEnvelope,
  faUserMd,
  faCircle,
  faUserTie,
  faTasks,
  faCreditCard,
  faEye,
  faEyeSlash,
  faArrowDown,
  faArrowUp,
  faBellSlash,
  faCloudDownloadAlt,
  faPaperclip,
  faEnvelopeOpen,
  faPlay,
  faVideo,
  faShare,
  faSms,
  faBars,
  faChartPie,
  faClock,
  faCalendar,
  faCalendarCheck,
  faCircle,
  faExclamationCircle,
  faThermometerEmpty,
  faCalendarAlt,
  faEllipsisH,
  faEllipsisV,
  faLink,
  faBolt,
  faFileMedicalAlt,
  faShareAll,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faDownload,
  faUserPlus,
  faSpinnerThird,
  faEdit,
  faClone,
  faBell,
  faFileMedical,
  faStickyNote,
  faCommentAltDots,
  faPlayCircle,
  faPlus,
  faMinus,
  faHandHoldingMedical,
  faPhoneVolume,
  faNotesMedical,
  faMobile,
  faPhoneRotary,
  faCalendarDay,
  faPencil,
  faLevelUp,
  faInbox,
  faBookmark,
  faThumbtack,
  faStar,
  faHistory,
  faCaretLeft,
  faCaretRight,
  faQuestionCircle,
  faLocation,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
