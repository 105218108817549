import AuthService from '@/services/AuthService'
import router from '@/router'
import Vue from 'vue'
import { UserApi, PracticeApi } from '@/services/'
import * as Sentry from '@sentry/vue'
import helpers from '@/helpers'

export const USER_TYPE = {
  LOGGED_OUT: -1,
  GUEST: 0,
  PUBLIC: 1,
  FULL: 2,
}

const state = {
  //may need to slim down user object, the user.practices could be too large for localStorage
  user: {},
  logins: [],
  token: '',
  resetToken: '',
  error: '',
  isTrackingLoaded: false,
  currentPracticeId: null,
  location: null,
  ipLocation: null,
}

const mutations = {
  setCurrentUser(state, user) {
    let cloneUser = { ...user }
    if (cloneUser?.token) delete cloneUser.token
    if (cloneUser?.is_guest && !cloneUser.practice?.id)
      cloneUser.practice = {
        id: cloneUser.practice.id,
        phone: cloneUser.practice.phone,
        is_setup_complete: true,
        status: 1,
      }
    state.user = cloneUser
    state.currentPracticeId = cloneUser?.practice?.id
  },
  setLogin(state, userAndToken) {
    try {
      const exisiting = state.logins.find((l) => l.id === userAndToken.id)
      if (exisiting) {
        state.logins = state.logins.filter((s) => s.id !== userAndToken.id)
        userAndToken.token = userAndToken.token || exisiting.token
      }
      state.logins.push({ ...userAndToken })
    } catch (err) {
      console.error(err)
    }
  },
  removeCurrentUser(state) {
    state.logins = state.logins.filter((s) => s.id !== state.user.id)
  },
  removeAllUsers(state) {
    state.logins = []
    state.token = ''
    state.resetToken = ''
    state.isTrackingLoaded = false
    state.user = {}
    state.currentPracticeId = null
  },
  setToken(state, token) {
    state.token = token
  },
  setResetToken(state, resetToken) {
    state.resetToken = resetToken
  },
  setSmsRead(state, { number, timetoken }) {
    if (!state.user || !state.user.practice) return

    if (!state.user.practice.sms_read_history) Vue.set(state.user.practice, 'sms_read_history', {})

    Vue.set(state.user.practice.sms_read_history, number, timetoken)
  },
  setError(state, error) {
    state.error = error
  },
  setIsTrackingLoaded(state, isTrackingLoaded) {
    state.isTrackingLoaded = isTrackingLoaded
  },
  enableIntegration(state, isEnabled) {
    state.user.practice.is_integration_active = isEnabled
  },
}

const actions = {
  setCurrentUser({ commit, dispatch, state }, user) {
    commit('setCurrentUser', user)

    if (user?.practice?.city && user.practice.location?.latitude && !user.is_guest) {
      const location = {
        lat: user?.practice?.location?.latitude,
        lon: user?.practice?.location?.longitude,
        zip: user?.practice?.zip,
        city: user?.practice?.city,
        state: user?.practice?.state,
        id: 'practiceLocation',
      }
      dispatch('geo/setLocation', location, { root: true })
    }
  },
  signup({ commit, dispatch }, data) {
    return AuthService.signup(data)
  },
  setSmsRead({ commit, state, rootState }, number) {
    number = number || rootState.sms.activeContactId
    commit('setSmsRead', { number, timetoken: new Date().getTime() * 10000 })
    //todo: this could be optimised, maybe an endpoint on the backend solely for updating
    //i.e /user/me/channel-key/setread or even subscribe to a pubnub channel on the backend
    PracticeApi.save(state.user.practice)
  },
  authEmail(context, credentials) {
    if (credentials && credentials.password) console.warn('Passwordless login should be favoured')
    return AuthService.authEmail(credentials.email).catch((response) => {
      response
        .json()
        .then((errors) => {
          if (errors?.email) context.commit('setError', errors?.email[0])
          else if (errors && errors.non_field_errors) context.commit('setError', errors.non_field_errors[0])
          else throw errors
        })
        .catch((err) => {
          context.commit('setError', err?.detail || 'An unexpected error occured.')
          throw err
        })
      throw response
    })
  },
  login(context, credentials) {
    if (credentials && credentials.password) console.warn('Passwordless login should be favoured')
    return AuthService.login(credentials.email, credentials.token)
      .then(({ token }) => {
        context.commit('setToken', token)
        context.commit('setError', '')
        return context.dispatch('me', { token })
      })
      .catch((response) => {
        response.json().then((errors) => {
          if (errors?.token) context.commit('setError', errors?.token[0])
          else if (errors && errors.non_field_errors) context.commit('setError', errors.non_field_errors[0])
          else throw errors
        })
        throw response
      })
  },
  loginWithPassword(context, credentials) {
    return AuthService.loginWithPassword(credentials.email, credentials.password)
      .then((data) => {
        context.commit('setToken', data.token)
        context.commit('setError', '')
        return context.dispatch('me', { token: data.token })
      })
      .catch((response) => {
        response
          .json()
          .then((errors) => {
            if (errors && errors.non_field_errors) context.commit('setError', errors.non_field_errors[0])
            else throw errors
          })
          .catch((err) => {
            context.commit('setError', 'An unexpected error occured.')
            Sentry.captureException(err)
            throw err
          })
      })
  },
  inviteLogin(context, { inviteKey, referral, opreport }) {
    return AuthService.inviteLogin(inviteKey, referral, opreport)
      .then(async (response) => {
        const data = await response.json()
        context.commit('setToken', data.token)
        context.commit('setResetToken', data.reset_token)
        context.commit('setError', '')
        await context.dispatch('me', { token: data.token })
        if (data.redirect) {
          if (data.message) context.commit('setError', data.message)
          router.push(data.redirect)
        }
      })
      .catch((response) => {
        response
          .json()
          .then((errors) => {
            if (errors?.redirect) {
              if (errors?.message) context.commit('setError', errors.message)
              router.push(errors.redirect)
              return
            }
            if (errors && errors.non_field_errors) context.commit('setError', errors.non_field_errors[0])
            else throw errors
          })
          .catch((err) => {
            context.commit('setError', 'An unexpected error occured.')
            Sentry.captureException(err)
            throw err
          })
      })
  },
  loginPublic(context) {
    return AuthService.loginPublicUser()
      .then(({ token }) => {
        context.commit('setToken', token)
        context.commit('setError', '')
        return context.dispatch('me', { token })
      })
      .catch((response) => {
        response.json().then((errors) => {
          if (errors?.token) context.commit('setError', errors?.token[0])
          else if (errors && errors.non_field_errors) context.commit('setError', errors.non_field_errors[0])
          else throw errors
        })
        throw response
      })
  },
  me({ commit, state, dispatch }, payload) {
    const currentToken = (payload && payload.token) || state.token

    commit('setToken', currentToken)
    return AuthService.me(true).then(async (user) => {
      commit('setToken', currentToken)
      await dispatch('setCurrentUser', user)
      commit('setLogin', {
        ...user,
        token: payload?.token || state.token,
      })
      return user
    })
  },
  forgotPassword(context, { email }) {
    return AuthService.forgotPassword(email).catch((response) => {
      response
        .json()
        .then((errors) => {
          if (!errors) throw 'Unexpected error occured'
          let errorKeys = Object.keys(errors)
          if (errors.length) context.commit('setError', errors[0])
          else if (errorKeys.length && errors[errorKeys[0]].length) context.commit('setError', errors[errorKeys[0]][0])
          else throw errors
        })
        .catch(() => {
          context.commit('setError', 'An unexpected error occured.')
        })
      throw response
    })
  },
  changePassword({ state, commit }, { uid, token, password }) {
    return AuthService.changePassword(uid || btoa(state.user.id), token || state.resetToken, password).catch(
      (response) => {
        response
          .json()
          .then((errors) => {
            if (!errors) throw 'Unexpected error occured'
            let errorKeys = Object.keys(errors)
            if (errorKeys.length && errors[errorKeys[0]].length) commit('setError', errors[errorKeys[0]][0])
            else throw errors
          })
          .catch(() => {
            commit('setError', 'An unexpected error occured.')
            throw response
          })
      }
    )
  },
  activateFromGuest({ state, commit }, { password, owner_first_name, owner_last_name }) {
    return AuthService.activateFromGuest(
      btoa(state.user.id),
      state.resetToken,
      password,
      owner_first_name,
      owner_last_name
    ).catch((response) => {
      response
        .json()
        .then((errors) => {
          if (!errors) throw 'Unexpected error occured'
          let errorKeys = Object.keys(errors)
          if (errorKeys.length && errors[errorKeys[0]].length) commit('setError', errors[errorKeys[0]][0])
          else throw errors
        })
        .catch((err) => {
          commit('setError', 'An unexpected error occured.')
          Sentry.captureException(err)
        })
    })
  },
  loginViaToken({ commit, dispatch }, { token }) {
    commit('setError', '')
    commit('setToken', token)

    return dispatch('me', { token })
  },
  switchUser({ state, dispatch, commit }, { userId, practiceId, token }) {
    try {
      let selectedUser = state.logins.find((s) => s.id === userId)
      if (!selectedUser) throw `Cannot switch to ${userId} as the login does not appear to be in state`
      commit('setToken', token || selectedUser.token)
      let practice =
        selectedUser.practices.find((s) => s.id === practiceId) || selectedUser.practice || state.user.practice
      dispatch('setCurrentUser', { ...selectedUser, practice })
    } catch (err) {
      console.error(err)
    }
  },
  switchPractice({ state, dispatch, commit }, id) {
    if (state.currentPracticeId == id) {
      return
    }
    let selectedPractice = state.user.practices.find((s) => s.id == id)
    if (!selectedPractice) throw `You do not belong to the requested practice.`
    dispatch('setCurrentUser', { ...state.user, practice: selectedPractice })
  },
  logout({ state, commit, dispatch }) {
    //todo: track isLoggingOut to prevent rate limit
    return AuthService.logout()
      .catch((err) => {
        // a 401 here means that the token as likely expired and /logout fails,
        // so we gracefully continue to dispatch a client logout.
        if (err.status !== 401) throw err
      })
      .finally(() => {
        dispatch('clientLogout')
      })
  },
  clientLogout({ state, commit, dispatch }, preventRedirect) {
    commit('removeCurrentUser')
    commit('setToken', null)
    router.push('/login')
    return
    const nextAvailableLogin = state.logins && state.logins.length && state.logins[0]
    commit('setToken', (nextAvailableLogin && nextAvailableLogin.token) || '')
    dispatch('setCurrentUser', nextAvailableLogin || null)
    //this should be handled by router
    if (!nextAvailableLogin && !(router.currentRoute && router.currentRoute.meta.isPublic)) router.push('/login')
    else router.push('/')
  },
  clientLogoutAll({ state, commit }) {
    commit('removeAllUsers')
    if (!(router.currentRoute && router.currentRoute.meta.isPublic)) router.push('/login')
  },
  clearError({ commit }) {
    commit('setError', '')
  },
  async updateCurrentUser({ commit, dispatch }, user) {
    await dispatch('setCurrentUser', user)
    commit('setLogin', user)
  },
  setTrackingLoaded({ commit }) {
    commit('setIsTrackingLoaded', true)
  },
  async promotePublicUser({ commit, dispatch }, { doctor, referralId }) {
    var user = await AuthService.promotePublicUser(doctor, referralId)
    await dispatch('me')
    await dispatch('practices/refreshCurrentUserPractice', user, { root: true })
  },
  async registerPublicUser(
    { commit, dispatch },
    { firstName, lastName, officeName, officePhone, timezone, zip, toPracticeId }
  ) {
    var { token } = await AuthService.registerPublicUser(
      firstName,
      lastName,
      officeName,
      officePhone,
      timezone,
      zip,
      toPracticeId
    )
    commit('setToken', token)
    commit('setError', '')
    return await dispatch('me', { token })
  },
}

const getters = {
  isTokenAvailable: (state) => !!(state.token && state.user),
  isPublicLoggedIn: (state) => !!(state.token && state.user && state.user.is_guest),
  isLoggedIn: (state) => !!(state.token && state.user && state.user.practice && !state.user.is_guest),
  user: (state) => state.user,
  isOrganization: (state, getters) =>
    getters.isLoggedIn && state.user.practice.organizations && !!state.user.practice.organizations.length,
  isSuperUser: (state, getters) => getters.isLoggedIn && state.user.is_superuser,
  isIntegrationActive: (state, getters) => getters.isLoggedIn && state.user.practice.is_integration_active,
  isDemo: (state) =>
    !!(state.token && state.user && state.user.practice && state.user.practice.email === 'demo@refera.com'),
  otherLocations: (state) => {
    const othersWithToken = (state.logins || [])
      .filter((s) => !s.is_guest && s.practice.other_locations?.length)
      .flatMap((s) => [...s.practice.other_locations, { ...s.practice, token: s.token }])
    return othersWithToken.filter((s) => s.id != state.user.practice.id)
  },
  otherUsers: (state, getters) => {
    const locationTokens = getters.otherLocations.map((s) => s.token)

    return state.logins.filter((s) => !locationTokens.includes(s.token))
  },
  otherPractices: (state, getters) => {
    return state.user.practices.filter((s) => s.id != state.user.practice.id).map((s) => ({ ...s, token: state.token }))
  },

  availableLogins: (state, getters) => {
    return state.logins.map((s) => ({ id: s.id, name: helpers.displayUsername(s), token: s.token }))
  },

  type: (state, getters, root, rootGetters) => {
    let user = state.user
    return user?.practice?.id
      ? user?.practice?.id == rootGetters['settings/AnonId'] || user?.practice?.id == rootGetters['settings/DemoId']
        ? USER_TYPE.GUEST
        : user.practice.is_setup_complete
        ? USER_TYPE.FULL
        : USER_TYPE.PUBLIC
      : USER_TYPE.LOGGED_OUT
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
