import FEATURES from '@/constants/premium-features'
import { PracticeApi } from '@/services'

const state = {
  plans: [],
  subscriptions: [],
  charges: [],
  isLoadingPlans: false,
  isLoadingSubscriptions: false,
  isLoadingCharges: false
}

const mutations = {
  setPlans(state, plans) {
    state.plans = plans
  },
  setSubscriptions(state, subscriptions) {
    state.subscriptions = subscriptions
  },
  setCharges(state, charges) {
    state.charges = charges
  },
  setLoadingSubscriptions(state, isLoading) {
    state.isLoadingSubscriptions = isLoading
  },
  setLoadingCharges(state, isLoading) {
    state.isLoadingCharges = isLoading
  },
  setLoadingPlans(state, isLoading) {
    state.isLoadingPlans = isLoading
  }
}

let loader

const actions = {
  async load({ state, dispatch }) {
    if (state.isLoadingPlans) return loader
    loader = Promise.all([
      dispatch('loadRelated', 'Plans'),
      dispatch('loadRelated', 'Subscriptions'),
      dispatch('loadRelated', 'Charges')
    ])
    return loader
  },
  async loadRelated({ commit, state, rootGetters, rootState }, name) {
    commit(`setLoading${name}`, true)
    try {
      let response = await PracticeApi.related(
        rootState.auth.user.practice.id,
        name.toLowerCase()
      )
      commit(`set${name}`, response)
    } catch (err) {
      throw err
    }
    commit(`setLoading${name}`, false)
  },
  reset({ commit }) {
    commit('setPlans', [])
    commit('setSubscriptions', [])
    commit('setCharges', [])
  },
  onAuthChange: ({ dispatch }, { isPracticeChanged, type }) => {
    if (isPracticeChanged) {
      dispatch('reset')
      if (type>0) dispatch('load')
    }
  }
}

const getters = {
  plans: (state, getters, root, rootGetters) => {
    const practice = root?.auth?.user?.practice
    const isGp = practice?.specialty == 0
    return [
      // {
      //   name: 'Basic',
      //   amount: 0,
      //   term: 'forever',
      //   isCurrent: !state.subscriptions.length
      // },
      ...state.plans.map(p => ({
        ...p,
        isSubscribed: state.subscriptions.map(s => s.plan).includes(p.id),
        features: FEATURES.filter(
          s =>
            (!s.isSpecialistOnly || root?.auth?.user?.practice?.specialty) &&
            (!s.planMeta || s.planMeta.includes(p.metadata?.features))
        )
      }))
    ]
  },
  features: (state, getters, root) =>
    FEATURES.filter(
      s => !s.isSpecialistOnly || root?.auth?.user?.practice?.specialty
    ),
  isPremium: (state, getters, root, rootGetters) =>
    root?.auth?.user?.practice?.has_active_subscription,
  activeSubscription: (state, getters, root, rootGetters) =>
    state.subscriptions?.[0]
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
