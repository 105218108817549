import moment from 'moment'
const state = {
  activeTour: null,
  step: -1,
  previous: -1,
  currentMockPractice: null,
  currentPrintListener: null,
  currentMockReferral: null
}

const mutations = {
  setActiveTour(state, tour) {
    state.activeTour = tour
    state.step = 0
  },
  setStep(state, step) {
    state.step = step
  },
  setPrevious(state, previous) {
    state.previous = previous
  },
  setCurrentMockPractice(state, practice) {
    state.currentMockPractice = practice
  },
  setCurrentMockReferral(state, referral) {
    state.currentMockReferral = referral
  }
}

const actions = {
  setActiveTour({ commit, dispatch }, tour) {
    commit('setActiveTour', tour)
    if (tour) {
      dispatch('settings/setIsGettingStartedVideoVisible', false, {
        root: true
      })
      dispatch('appointments/load', null, { root: true })
    }
  },
  setStep({ commit, state }, { step, previous }) {
    if (!state.activeTour) return
    commit('setPrevious', previous)
    commit('setStep', step)
  },
  next({ commit, state }) {
    if (!state.activeTour) return
    commit('setPrevious', state.step)
    commit('setStep', state.step + 1)
  },
  async reset({ commit, dispatch }) {
    commit('setActiveTour', null)
    commit('setPrevious', -1)
    commit('setStep', -1)

    //clean is_example practice
    await dispatch('practices/reset', null, { root: true })
    dispatch('practices/load', null, { root: true })
    await dispatch('suggested/reset', null, { root: true })
    dispatch('suggested/load', {}, { root: true })
    await dispatch('referrals/reset', null, { root: true })
    dispatch('referrals/load', null, { root: true })
    await dispatch('appointments/reset', null, { root: true })
    dispatch('appointments/load', null, { root: true })
  },
  async specialtySelected({ commit, state, dispatch, getters }, specialty) {
    if (!state.activeTour) return
    if (state.step == 1 && specialty == 1) {
      const mockPractice = getters.mockPractice
      await dispatch('suggested/load', {}, { root: true })
      commit('setCurrentMockPractice', mockPractice)
      await dispatch('suggested/add', mockPractice, { root: true })
      await dispatch('next')
    }
  }
}
const nextBtnDefault = 'OK'
const getters = {
  currentTarget: (state, getters) => state.step >= 0 && getters.getSteps()[state.step].target,
  currentStep: (state, getters) => store => state.step >= 0 && getters.getSteps(store)[state.step],
  mockPractice: (state, getters, root) =>
    root.auth.token &&
    root.auth.user &&
    root.auth.user.practice && {
      id: 'TOUR_PRACTICE',
      name: 'Simple Smiles Endo',
      website: 'http://www.simplesmilesendo.com',
      phone: '1234567890',
      email: 'smiles@office.com',
      address: '123 Example Street',
      address_2: '',
      city: root.auth.user.practice.city,
      state: root.auth.user.practice.state,
      zip: root.auth.user.practice.zip,
      specialty: 1,
      npi: 'MANUAL_ADD',
      status: 1,
      owner_doctor_first: 'John',
      owner_doctor_last: 'Smith',
      is_example: true,
      is_in_network: false,
      distance: 0,
      doctors: [
        { id: -1, first_name: 'Sam', last_name: 'Smiles' },
        { id: -2, first_name: 'John', last_name: 'Smith' }
      ]
    },
  mockPatient: state => ({
    phone: '(123) 456 7890',
    email: 'patient@example.com',
    birthdate: '10/10/1990',
    primary_insurance_network: 'Delta',
    primary_insurance_subscriber: 'Jane Doe',
    primary_insurance_membership: '1234567890'
  }),
  mockReferral: (state, getters, root) => (store, is_incoming) => ({
    archived_from_time: null,
    archived_to_time: null,
    attachments: [
      {
        id: -1,
        file: 'https://via.placeholder.com/150',
        url: 'https://via.placeholder.com/150',
        name: 'example-xray.png',
        size: 36525,
        item_date: null,
        created_by: root.auth.user,
        created_at: '2021-01-16T05:45:56.172303Z',
        updated_at: '2021-01-16T05:45:56.172303Z',
        updated_by: root.auth.user
      }
    ],
    availibility: '',
    cancelled_time: null,
    comments: '',
    completed_time: null,
    created_at: '2020-12-21T05:26:02.294036Z',
    created_by: root.auth.user,
    doctor: 'Any (First Available)',
    followup_completed_time: null,
    guardian_name: null,
    history: [],
    id: 'example-referral',
    is_incoming: false,
    last_email_status: 'sent',
    last_email_status_time: '2020-12-22T06:52:25.348111Z',
    needs_action: true,
    patient_dob: '2021-01-16T06:51:47.231664Z',
    patient_email: 'Not Available',
    patient_firstname: 'Jane',
    patient_internal_id_sender: '',
    patient_lastname: 'Doe',
    patient_middlename: null,
    patient_phone: '(111) 222-3333',
    practice: root.auth.user.practice,
    practice_id: root.auth.user.practice.id,
    preferred_contact_method: 'any',
    radiographs_mailed: false,
    radiographs_panorex: false,
    radiographs_patient: false,
    reasons: ['retreatment_including_post_and_build_up'],
    referring_doctor: root.auth.user,
    referring_doctor_id: root.auth.user.id,
    requested_scheduled_time: null,
    requires_followup: false,
    scheduled_at_time: null,
    scheduled_time: null,
    sent_time: null,
    snooze_until_from_time: null,
    snooze_until_to_time: null,
    teeth: ['LL', 'LR', 'UL', 'UR'],
    to_practice: root.auth.user.practice,
    to_practice_id: root.auth.user.practice.id,
    type: null,
    updated_at: '2020-12-22T06:52:23.260199Z',
    updated_by: root.auth.user.practice,
    is_patient_opted_in: true,
    is_marked_scheduled: false
  }),
  mockOpReport: (state, getters, root) => (store, isIncoming) => ({
    id: 7,
    referring_doctor: root.auth.user,
    referring_doctor_id: root.auth.user.id,
    referral_id: null,
    patient_firstname: 'John',
    patient_lastname: 'Smith',
    patient_dob: '1987-05-04',
    teeth: ['LL', '28'],
    comment:
      'The patient presented with pain and mobility in the maxillary right quadrant. She had a history of periodontal disease and has been undergoing maintenance therapy. Tooth #4 has recently become more mobile and has been declared unrestorable by the periodontist',
    created_at: '2021-02-09T01:39:36.987216Z',
    sent_time: '2021-02-09T01:40:28.392000Z',
    practice: root.auth.user.practice,
    practice_id: root.auth.user.practice.id,
    to_practice: root.auth.user.practice,
    to_practice_id: root.auth.user.practice.id,
    attachments: [
      {
        id: 111,
        file: 'https://refera.com/images/dental-xray.jpg',
        url: 'https://refera.com/images/dental-xray.jpg',
        name: 'Patient X-Ray.jpg',
        size: 785245,
        item_date: moment().toISOString(),
        created_by: root.auth.user,
        created_at: '2021-02-09T01:39:55.983594Z',
        updated_at: '2021-02-09T01:39:55.983594Z',
        updated_by: root.auth.user
      }
    ],
    is_incoming: true
  }),
  getSteps: (state, getters, root) => store =>
    [
      {
        target: '[data-v-tour="createReferral"]',
        content: 'Click "Create Referral"',
        params: { placement: 'bottom' }
        // onEnter: async () => {
        //   if (router.app._route.path === '/')
        //     await new Promise(resolve => setTimeout(resolve, 500))
        //   else await new Promise((res, rej) => router.replace('/', res, res))
        // }
      },
      {
        target: '[data-v-tour="selectSpecialtyEndodontist"]',
        content: 'Now we select the specialty, select "Endodontist"',
        params: { placement: 'top' }
      },
      // {
      //   target: '[data-v-tour="networkResults"]',
      //   content:
      //     // 'The list shows recently referred to offices. We also show you nearby offices as suggestions.',
      //     'You can refer to anyone!  Search or use our suggested.',
      //   params: { placement: 'top', enableScrolling: true },
      //   nextBtn: nextBtnDefault
      // },
      {
        target: '[data-v-tour="examplePracticeSelect"]',
        content: 'Select the example office',
        params: { placement: 'left' }
      },
      // {
      //   target: '[data-v-tour="examplePractice"] td',
      //   content:
      //     "Since they haven't used Refera before; we need to provide an email",
      //   //"We need to provide an email the first time",
      //   nextBtn: nextBtnDefault,
      //   params: { placement: 'top' }
      // },
      // {
      //   target: '[data-v-type="examplePracticeAdd"]',
      //   content: 'We enter their email',
      //   params: { placement: 'top' },
      //   disabledInteraction: true,
      //   onEntered: async () => {
      //     new TypeIt('[data-v-type="examplePracticeAdd"]', {
      //       strings: 'smiles@office.com',

      //       afterComplete: async () => {
      //         return await store.dispatch('tour/next')
      //       }
      //     }).go()
      //   }
      // },
      // {
      //   target: '[data-v-tour="examplePracticeAddButton"]',
      //   content: 'Hit "Finish"',
      //   params: { placement: 'left', enableScrolling: true }
      // },
      {
        target: '[data-v-tour="createReferralContainer"]',
        content: "Now let's send a referral",
        params: { placement: 'top' },
        nextBtn: nextBtnDefault,
        inlineBtn: true
      },
      {
        target: '[data-v-tour="patientDetails"]',
        content: 'Provide a patient name and phone',
        params: { placement: 'top', enableScrolling: true },
        nextBtn: nextBtnDefault,
        whiteBg: true
      },
      {
        target: '[data-v-tour="referralReasons"]', // .tags-input-badge:nth-child(4)',
        content: 'Select a reason for the referral',
        params: { placement: 'top', enableScrolling: true },
        whiteBg: true
      },
      // {
      //   target: '[data-v-tour="referralReasons"]',
      //   content: "Let's set the reason for referral",
      //   params: { placement: 'top', enableScrolling: true },
      //   nextBtn: nextBtnDefault
      // },
      // {
      //   target: '[data-v-tour="referralReasonEl"] .tags-input',
      //   content: "You can also type in custom reasons",
      //   params: { placement: 'top' },
      //   onEnter: async () => {
      //     const el = document.querySelector('[data-v-tour="referralReasonEl"] .tags-input input');
      //     el.focus()
      //     new TypeIt(el, {
      //       strings: 'Patient is symptomatic (hot and cold sensitivities)',

      //       afterComplete: async () => {
      //         el.blur();
      //         return await store.dispatch('tour/next');
      //       },
      //     }).pause(750).go();
      //   },
      //   disabledInteraction: true,
      // },
      {
        target: '[data-v-tour="selectTooth"]',
        content: 'Select a tooth',
        params: { placement: 'bottom', enableScrolling: true },
        whiteBg: true
      },
      // {
      //   target:
      //     '[data-v-tour="appointmentPicker"] .hooper-slide:nth-child(11) > div',
      //   content: 'Click the appointment',
      //   params: { placement: 'top', enableScrolling: true },
      //   anyClick: true
      // },
      // {
      //   target: '[data-v-tour="referralNextToPatient"]',
      //   content: 'Hit "Next"',
      //   params: { placement: 'top', enableScrolling: true },
      //   anyClick: true
      // },
      // {
      //   target: '[data-v-tour="patientDetails"]',
      //   content: 'We enter our patient information',
      //   params: { placement: 'top', enableScrolling: true },
      //   anyClick: false,
      //   onEntered: async () => {
      //     new TypeIt('[name="patient_firstname"]', {
      //       strings: 'Joe',

      //       afterComplete: async () => {
      //         new TypeIt('[name="patient_lastname"]', {
      //           strings: 'Smith',

      //           afterComplete: async () => {
      //             new TypeIt('[name="patient_phone"]', {
      //               strings: '1234567890',

      //               afterComplete: async () => {
      //                 store.commit('tour/setCurrentMockReferral', {
      //                   patient_firstname: 'Joe',
      //                   patient_lastname: 'Smith',
      //                   patient_phone: '1234567890'
      //                 })
      //                 // router.push()
      //                 return await store.dispatch('tour/next')
      //               }
      //             }).go()
      //           }
      //         }).go()
      //       }
      //     }).go()
      //   }
      // },
      // {
      //   target: '[data-v-tour="appointmentPicker"]',
      //   content:
      //     'With our integration you can see all of your current appointments',
      //   params: { placement: 'top', enableScrolling: true },
      //   nextBtn: nextBtnDefault
      // },
      // {
      //   target: '[data-v-tour="patientContact"]',
      //   content:
      //     "Patient's will receive a text and/or phone confirmation when their referral has been <b>sent</b> and <b>scheduled</b>.",
      //   params: { placement: 'top', enableScrolling: true },
      //   nextBtn: nextBtnDefault
      // },
      {
        target: '[data-v-tour="uploads"]',
        content: 'You can optionally attach any documents, images or x-rays',
        params: { placement: 'top' },
        nextBtn: nextBtnDefault
      },
      {
        target: '[data-v-tour="sendReferral"]',
        content: 'Hit "Send"',
        params: { placement: 'bottom' }
      },
      // {
      //   target: '[data-v-tour="previewReferral"]',
      //   content: 'Here you can see a preview of the referral',
      //   params: { placement: 'top' },
      //   nextBtn: nextBtnDefault
      // },
      // {
      //   target: '[data-v-tour="printReferral"]',
      //   content: 'You can optionally print',
      //   params: { placement: 'top' },
      //   nextBtn: nextBtnDefault
      // },
      // {
      //   target: '[data-v-tour="confirmTeeth"]',
      //   content: 'Confirm the selected teeth are correct',
      //   params: { placement: 'top' },
      //   anyClick: true
      // },
      {
        target: '[data-v-tour="confirmReferral"]',
        content: 'Click "Confirm & Send"',
        params: { placement: 'left' },
        onEntered: async () => {
          //todo: scroll preview
        }
      },
      {
        target: '[data-v-tour="topBarStatus"]',
        content:
          "That's it! The referral has been sent. The office will receive an email with a link to view referral.",
        params: { placement: 'bottom' },
        nextBtn: nextBtnDefault,
        onEntered: () => {
          let r = root.referrals.referrals[root.referrals.referrals.length - 1]
          setTimeout(() => {
            if (!root.tour.activeTour) return
            const now = moment().toISOString()
            r.receiver_viewed_at_time = now
            store.commit('referrals/saveReferral', r)
          }, 2500)
        }
      }
      // {
      //   target: 'table.referral-list [data-v-tour="referralRowStatus"] > div > div',
      //   content: 'You can see the current status here. For example, it will change once it has been scheduled.',
      //   params: { placement: 'top' },
      //   nextBtn: 'Show Me'
      // },
      // {
      //   target: 'table.referral-list [data-v-tour="referralRowStatus"] > div > div',
      //   content: 'Now it shows as scheduled',
      //   params: { placement: 'top' },
      //   nextBtn: nextBtnDefault,
      //   onEnter: async () => {
      //     const mockUser = { "email": "jblogs@example.com", "id": 2, "first_name": "Joe", "last_name": "Bloggs", "is_doctor": true };
      //     const referral = store.getters['referrals/get']('tour-0');
      //     referral.scheduled_at = moment().utc().toISOString();
      //     referral.scheduled_time = moment().add(1, 'days').set({ "hour": 14, "minute": 30 }).utc().toISOString()
      //     referral.history.push({
      //       "id": 123, "details": "Left VM", "type": "CUSTOM",
      //       "created_by": mockUser,
      //       "created_at": moment().subtract(3, 'hours').utc().toISOString()
      //     })
      //     // referral.history.push({
      //     //   "id": 124, "details": "Patient's arm in a cast", "type": "CUSTOM",
      //     //   "created_by": mockUser,
      //     //   "created_at": moment().subtract(2, 'hours').utc().toISOString()
      //     // })
      //     referral.history.push({
      //       "id": 125, "details": "Appointment Scheduled", "type": "SYSTEM",
      //       "created_by": mockUser,
      //       "created_at": moment().utc().toISOString()
      //     })
      //     store.dispatch('referrals/save', referral)
      //   },
      // },
      // {
      //   target: 'table.referral-list [data-v-tour="referralRow"]',
      //   content: 'Click the row to see more details.',
      //   params: { placement: 'top' },
      // },
      // {
      //   target: 'table.referral-list [data-v-tour="referralRowDetails"]',
      //   content: 'GP\'s and specialist will see this view',
      //   params: { placement: 'top' },
      //   nextBtn: nextBtnDefault,
      // },
      // {
      //   target: '[data-v-tour="NeedsActionLink"]',
      //   content: 'When the referrals require attention they are shown in your "Needs Action" view',
      //   params: { placement: 'top' },
      //   nextBtn: 'Show me',
      // },
      // {
      //   target: 'table.referral-list [data-v-tour="referralRowStatus"] > div > div',
      //   content: 'The referral has been marked completed and it requires follow up',
      //   params: { placement: 'top' },
      //   onEnter: async () => {
      //     const mockUser = { "email": "jblogs@example.com", "id": 2, "first_name": "Joe", "last_name": "Bloggs", "is_doctor": true };
      //     const referral = store.getters['referrals/get']('tour-0');
      //     referral.completed_time = moment().add(1, 'days').set({ "hour": 16, "minute": 45 }).utc().toISOString()
      //     referral.requires_followup = true;
      //     referral.history.push({
      //       "id": 126, "details": "Extraction requires follow-up", "type": "CUSTOM",
      //       "created_by": mockUser,
      //       "created_at": moment().subtract(2, 'hours').utc().toISOString()
      //     })
      //     referral.history.push({
      //       "id": 127, "details": "Referral Completed", "type": "SYSTEM",
      //       "created_by": mockUser,
      //       "created_at": moment().utc().toISOString()
      //     })
      //     store.dispatch('referrals/save', referral)
      //     store.dispatch('referrals/filter', 'Needs Action')
      //   },
      //   nextBtn: nextBtnDefault,
      // },
      // {
      //   target: 'table.referral-list [data-v-tour="referralAction"]',
      //   content: 'Acknowledge this by clicking the complete icon',
      //   params: { placement: 'top' },
      //   anyClick: true,
      // },
      // {
      //   target: '[data-v-tour="AllLink"]',
      //   content: 'The referral is now under "All"',
      //   params: { placement: 'top' },
      //   anyClick: true,
      // },
      // {
      //   target: root.auth.user.practice.email != 'demo@refera.com' ? '[data-v-tour="amazonOffer"]' : 'header.top-bar',
      //   content: root.auth.user.practice.email != 'demo@refera.com' ? 'Receive a $25 Amazon gift card by sending 10 referrals on Refera' : 'That\'s the basics. We also have a few more awesome features.',
      //   params: { placement: 'top' },
      //   anyClick: true,
      //   gpOnly: true,
      // },
    ]
      .filter(
        s =>
          (!s.gpOnly || root.auth.user.practice.specialty == 0) &&
          (root.auth.user.practice.email != 'demo@refera.com' || !s.isHiddenDemo)
      )
      .map(s => ({
        ...s,
        params: {
          removeOnDestroy: true,
          onCreate: popper => {
            popper.instance.update()
          },
          enableScrolling: false,
          ...s.params
        }
      }))
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
