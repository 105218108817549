import Vue from 'vue'
// import posthog from 'posthog-js'

Vue.prototype.Beacon = window.Beacon
const isProduction = process.env.VUE_APP_ENV_NAME === 'production'
const isInIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}
export default {
  install: function (vue, router, store) {
    if (isInIframe()) return
    // let isEnabled = true
    // try {
    //   //todo: posthog.onFeatureFlags in case not loaded
    //   isEnabled = posthog.isFeatureEnabled('help-scout-beacon', {
    //     send_event: false
    //   })
    // } catch {}
    // if (!isEnabled) return

    const setIdentity = (user) => {
      window.Beacon('logout', { endActiveChat: true })

      if (!user) return //tood: any deIntentify with posthog

      const { first_name, last_name, id, practice, is_doctor } = user
      if (practice && id) {
        const { email, phone, specialty } = practice
        const identity = {
          name: first_name + ' ' + last_name,
          email,
          signature: user.helpscout_key,
          company: practice.name,
          specialty: specialty,
          jobTitle: is_doctor ? 'Doctor' : 'Staff',
          'django-url': `${process.env.VUE_APP_API_BASE_URL}/admin/practices/practice/${practice.id}/`,
          'practice-id': practice.id,
          phone: phone.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
        }
        window.Beacon('identify', identity)
        window.Beacon('prefill', {
          name: first_name + ' ' + last_name,
          email,
          // subject: '',
          // text: '',
          // fields: [
          //   {
          //     id: 5678, // dropdown field ID
          //     value: 1234, // option ID
          //   },
          //   {
          //     id: 5679, // date field ID
          //     value: 'YYYY-MM-DD', // YYYY-MM-DD format
          //   },
          //   {
          //     id: 5680, // single, multi-line, or number field ID
          //     value: 'Question', // value expressed as a string
          //   },
          // ],
        })
      }
    }
    store.watch(
      (state) => state.auth.user,
      (user, oldUser) => {
        const isOldUser = !!Object.keys(oldUser).length
        const isNewUser = !!Object.keys(user).length
        const isLogout = isOldUser && !isNewUser
        if ((isOldUser && isNewUser && oldUser.id != user.id) || isLogout) {
          //reset
        }
        setIdentity(user)
      }
    )

    router.afterEach((to, from) => {
      window.Beacon('event', {
        type: 'page-viewed',
        url: document.location.href,
        title: document.title,
      })
      window.Beacon('session-data', {
        'App Version': process.env.VUE_APP_VERSION,
      })
    })

    var hsBeacon = document.createElement('script')
    hsBeacon.type = 'text/javascript'
    hsBeacon.async = true
    hsBeacon.defer = true
    hsBeacon.src = `https://beacon-v2.helpscout.net`
    hsBeacon.onload = () => (Vue.prototype.Beacon = window.Beacon)
    let isBeaconInstalled = false
    const installBeacon = () => {
      if (!isBeaconInstalled) {
        document.head.appendChild(hsBeacon)
      }
    }

    Vue.prototype.Beacon = window.Beacon = function (method, options, data) {
      //installBeacon is custom and allows us to preload the beacon script when needed
      if (method != 'installBeacon') window.Beacon.readyQueue.push({ method, options, data })
      if (method === 'open' || method === 'installBeacon') {
        installBeacon()
      }
    }

    //todo: Vue.protoype doesnt hold new reference of window.Beacon
    window.Beacon.readyQueue = []

    window.Beacon('init', 'f7416461-2e2d-43bf-ad8a-5a7d3c289fc8')
    window.Beacon('config', { display: { style: 'manual' } })

    // try {
    //   const isArticleEnabled = posthog.isFeatureEnabled('help-scout-beacon-article', {
    //     send_event: false,
    //   })
    //   if (!isArticleEnabled) return

    //   window.Beacon('article', '6341fbbc62a58869ee5efeb3', { type: 'modal' })
    //   posthog.capture('$helpscoutBeaconSeen')
    // } catch {}
  },
}

// 'use strict';
// (function(windowRef, doc, n) {
//   if (Vue.prototype.Beacon = windowRef.Beacon = n = function(i, n, a) {
//     windowRef.Beacon.readyQueue.push({
//       method : i,
//       options : n,
//       data : a
//     });
//   }, n.readyQueue = [], "complete" === doc.readyState) {
//     return __loadScript();
//   }
//   if (windowRef.attachEvent) {
//     windowRef.attachEvent("onload", __loadScript);
//   } else {
//     windowRef.addEventListener("load", __loadScript, false);
//   }
// })(window, document, window.Beacon || function() {});

// !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
// !(function(w, d, n) {
//   function a() {
//     var e = d.getElementsByTagName('script')[0],
//       n = d.createElement('script')
//     ;(n.type = 'text/javascript'),
//       (n.async = !0),
//       (n.src = 'https://beacon-v2.helpscout.net'),
//       e.parentNode.insertBefore(n, e)
//   }
//   if (
//     ((w.Beacon = n = function(t, n, a) {
//       w.Beacon.readyQueue.push({ method: t, options: n, data: a })
//     }),
//     (n.readyQueue = []),
//     'complete' === t.readyState)
//   )
//     return a()
//   e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1)
// })(window, document, window.Beacon || function() {})
